import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const TicketFormData = (props) => {
    //to update the page title
    useEffect(() => {
        document.title = `Ticket Form Data - EventHex Portal`;
    }, []);

    const [attributes] = useState([
        {
            type: "select",
            apiType: "API",
            selectApi: "ticket/select",
            placeholder: "Ticket",
            name: "ticket",
            validation: "",
            showItem: "title",
            default: "",
            label: "Ticket",
            required: true,
            view: true,
            add: true,
            update: true,
            filter: true,
        },
        {
            type: "number",
            placeholder: "Order Id",
            name: "orderId",
            validation: "",
            default: "",
            label: "Order Id",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "select",
            placeholder: "Type",
            name: "type",
            validation: "",
            default: "",
            tag: true,
            label: "Type",
            showItem: "Type",
            required: true,
            view: true,
            filter: false,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "select,multiSelect,text,textarea,image,checkbox,date,datetime,number,email,password",
        },
        {
            type: "text",
            placeholder: "Api Type",
            name: "apiType",
            condition: {
                item: "type",
                if: "select",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Api Type",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Api Type",
            name: "apiType",
            condition: {
                item: "type",
                if: "multiSelect",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Api Type",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Select Api",
            name: "selectApi",
            condition: {
                item: "type",
                if: "select",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Select Api",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Select Api",
            name: "selectApi",
            condition: {
                item: "type",
                if: "multiSelect",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Select Api",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Place Holder",
            name: "placeHolder",
            validation: "",
            default: "",
            label: "Place Holder",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Name",
            name: "name",
            validation: "",
            default: "",
            label: "Name",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "number",
            placeholder: "Validation",
            name: "validation",
            validation: "",
            default: "",
            label: "Validation",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Show Item",
            name: "showItem",
            validation: "",
            default: "",
            label: "Show Item",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Default",
            name: "default",
            validation: "",
            default: "",
            label: "Default",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Label",
            name: "label",
            validation: "",
            default: "",
            label: "Label",
            tag: true,
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "checkbox",
            placeholder: "Tag",
            name: "tag",
            validation: "",
            default: null,
            tag: true,
            label: "Tag",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "checkbox",
            placeholder: "Required",
            name: "required",
            validation: "",
            default: null,
            tag: true,
            label: "Required",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "checkbox",
            placeholder: "View",
            name: "view",
            validation: "",
            default: null,
            tag: true,
            label: "View",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "checkbox",
            placeholder: "Add",
            name: "add",
            validation: "",
            default: null,
            tag: true,
            label: "Add",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "checkbox",
            placeholder: "Update",
            name: "update",
            validation: "",
            default: null,
            tag: true,
            label: "Update",
            required: true,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "checkbox",
            placeholder: "Filter",
            name: "filter",
            validation: "",
            default: null,
            tag: true,
            label: "Filter",
            required: true,
            view: true,
            add: true,
            update: true,
        },
    ]);

    return (
        <Container className="noshadow">
            <ListTable
                // actions={actions}
                api={`ticket-form-data`}
                itemTitle={{ name: "title", type: "text", collection: "ticket" }}
                shortName={`Ticket Form Data`}
                formMode={`double`}
                {...props}
                attributes={attributes}
            ></ListTable>
        </Container>
    );
};
// exporting the page with parent container layout..
export default Layout(TicketFormData);
