import React from "react";
import Menu from "../../project/pages/menu";
import Franchise from "../../project/pages/franchise";
import Page404 from "../../project/pages/page404";
import UserType from "../../project/pages/user/userType";
import Dashboard from "../../project/pages/dashboard";
// import Admin from "../../project/pages/franchise/admin";
import Admin from "../../project/pages/franchise/admin";
import Collection from "../../project/pages/collection";
import Faq from "../../project/pages/faq";
import Gallery from "../../project/pages/gallery";
import News from "../../project/pages/news";
import Speakers from "../../project/pages/speakers";
import Registration from "../../project/pages/registration";
import Testimonial from "../../project/pages/testimonial";
import Event from "../../project/pages/event";
import EventUser from "../../project/pages/eventUser";
import CountDate from "../pages/count";
import Deconquista from "../../project/pages/deconquista";
import LeadersNote from "../../project/pages/leadersNote";
import PaidReg from "../../project/pages/paidReg";
import Attendance from "../../project/pages/attendance";
import PageSection from "../../project/pages/pageSection";
import SectionTheme from "../../project/pages/sectionTheme";
import Fpackage from "../../project/pages/franchisePackage";
import Packages from "../../project/pages/package";
import Settings from "../pages/settings";
import WhitelistedDomains from "../pages/whitelistedDomains";
import Ticket from "../pages/ticket";
import TicketFormData from "../pages/ticketFormData";
import TicketRegistration from "../pages/ticketFormData/ticketRegistration";
import Landing from "../pages/landing/landing";
import LandingPageConfig from "../pages/landingPageConfig";
import Elements from "../pages/settings/elements";
import CertificationData from "../pages/certificationData";
import Authentication from "../pages/authentication";
import Demo from "../pages/landing/demo";
import Country from "../pages/country";
import TicketAdmin from "../pages/event/ticketAdmin";

const RenderPage = (page, key, user, privileges) => {
  switch (page) {
    case "login":
      return <Landing key={key} />;
    case "demo-landing":
      return <Demo key={key} {...privileges} />;
    case "menu":
      return <Menu key={key} {...privileges} />;
    case "franchise":
      return <Franchise key={key} {...privileges} />;
    case "user-role":
      return <UserType key={key} {...privileges} />;
    case "admin":
      return <Admin key={key} {...privileges} />;
    case "faq":
      return <Faq key={key} {...privileges} />;
    case "dashboard":
      return <Dashboard key={key} {...privileges} />;
    case "collection":
      return <Collection key={key} {...privileges} />;
    case "gallery":
      return <Gallery key={key} {...privileges} />;
    case "news":
      return <News key={key} {...privileges} />;
    case "speakers":
      return <Speakers key={key} {...privileges} />;
    case "registration":
      return <Registration key={key} {...privileges} />;
    case "testimonial":
      return <Testimonial key={key} {...privileges} />;
    case "event":
      return <Event key={key} {...privileges} />;
    case "event-user":
      return <EventUser key={key} {...privileges} />;
    case "count":
      return <CountDate key={key} {...privileges} />;
    case "deconquista":
      return <Deconquista key={key} {...privileges} />;
    case "leadersNote":
      return <LeadersNote key={key} {...privileges} />;
    case "paid-reg":
      return <PaidReg key={key} {...privileges} />;
    case "attendance":
      return <Attendance key={key} {...privileges} />;
    case "page-section":
      return <PageSection key={key} {...privileges} />;
    case "section-theme":
      return <SectionTheme key={key} {...privileges} />;
    case "franchise-package":
      return <Fpackage key={key} {...privileges} />;
    case "package":
      return <Packages key={key} {...privileges} />;
    case "settings":
      return <Settings key={key} {...privileges} />;
    case "whitelistedDomains":
      return <WhitelistedDomains key={key} {...privileges} />;
    case "ticket":
      return <Ticket key={key} {...privileges} />;
    case "ticket-form-data":
      return <TicketFormData key={key} {...privileges} />;
    case "ticket-registration":
      return <TicketRegistration key={key} {...privileges} />;
    case "landingPageConfig":
      return <LandingPageConfig key={key} {...privileges} />;
    case "elements":
      return <Elements key={key} {...privileges} />;
    case "certification-data":
      return <CertificationData key={key} {...privileges} />;
    case "authentication":
      return <Authentication key={key} {...privileges} />;
    case "event-admin":
      return <Event key={key} {...privileges} />;
    case "demo":
      return <Demo key={key} {...privileges} />;
    case "country":
      return <Country key={key} {...privileges} />;
    case "ticket-admin":
      return <TicketAdmin key={key} {...privileges} />;
    default:
      return <Page404 key={key}></Page404>;
  }
};

export default RenderPage;
