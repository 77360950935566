import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import moment from "moment";
// import axios from "axios";
import registrationFormData from "../registrationFormData";
import landingPage from "./landingPage";
import ticketForm from "./ticketForm";
// import certificateFormData from "./certificateFormData";
import eventForm from "./eventForm";
import { checkprivilege, privileges } from "../../brand/previliage";
import { getData } from "../../../../backend/api";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Event = (props) => {
  //to update the page title
  useEffect(() => {
    document.title = `Event - EventHex Portal`;
  }, []);
  const userType = props.user.user.userType._id;
  const onChange = (name, updateValue) => {
    const { label } = updateValue;
    updateValue["placeHolder"] = label;
    return updateValue;
  };

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "OTP send",
      name: "registrationCount",
      validation: "",
      default: "",
      label: "OTP send",
      tag: true,
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "Verified",
      name: "authenticatedCount",
      validation: "",
      default: "",
      label: "Verified",
      tag: true,
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Venue",
      name: "venue",
      validation: "",
      default: "",
      label: "Venue",
      required: false,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      label: "Description",
      tag: true,
      required: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Start Date",
      name: "startDate",
      validation: "",
      default: "",
      label: "Start Date",
      minDate: moment().add(-70, "years").toDate(),
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "End Date",
      name: "endDate",
      validation: "",
      default: "",
      label: "End Date",
      minDate: moment().add(-70, "years").toDate(),
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Total Registration",
      name: "regCount",
      validation: "",
      default: "",
      label: "Total Registration",
      tag: false,
      required: false,
      view: false,
      add: false,
      update: false,
    },
    {
      type: "image",
      placeholder: "Logo",
      name: "logo",
      validation: "",
      default: "false",
      tag: true,
      label: "Logo",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Banner",
      name: "banner",
      validation: "",
      default: "false",
      tag: true,
      label: "Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Lucky Draw Background",
      name: "luckyDrawBackground",
      validation: "",
      default: "false",
      tag: true,
      label: "Lucky Draw Background",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Badge & Certificate",
      name: "Badge & Certificate",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Popup Banner",
      name: "popupBanner",
      validation: "",
      default: "",
      label: "Enable Popup Banner",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Popup Banner",
      name: "popupBannerImage",
      condition: {
        item: "popupBanner",
        if: "true",
        then: "enabled",
        else: "disabled",
      },
      validation: "",
      default: "false",
      tag: true,
      label: "Popup Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Sign In Methods",
      name: "authenticationType",
      selectApi: "Whatsapp,Mobile,Email,SocialPlugin",
      validation: "",
      default: "",
      label: "Sign In Methods",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Login Page",
      name: "loginPage",
      selectApi: "Vertical,Horizontal",
      validation: "",
      default: "",
      label: "Login Page",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Header",
      name: "header",
      validation: "",
      default: "",
      label: "Enable Header",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Header Theme",
      name: "headerTheme",
      selectApi: "theme1,theme2,theme3,theme4",
      validation: "",
      default: "",
      label: "Header Theme",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Footer",
      name: "footer",
      validation: "",
      default: "",
      label: "Enable Footer",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Footer Theme",
      name: "footerTheme",
      selectApi: "theme1,theme2,theme3,theme4,theme5,theme6",
      validation: "",
      default: "",
      label: "Footer Theme",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Mobile Menu Type",
      name: "mobileMenuType",
      selectApi: "Bottom Tabbed,Navbar",
      validation: "",
      default: "",
      label: "Mobile Menu Type",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Desktop Menu Style",
      name: "desktopMenuStyle",
      selectApi: "Theme 1,Theme 2",
      validation: "",
      default: "",
      label: "Desktop Menu Style",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Home Page",
      name: "homePage",
      selectApi: "Tickets,Sub Events",
      validation: "",
      default: "",
      label: "Home Page",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Is Active",
      name: "isActive",
      validation: "",
      default: "",
      label: "Is Active",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Theme Color",
      name: "themeColor",
      validation: "",
      default: "",
      label: "Theme Color",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Theme Text Color",
      name: "themeTextColor",
      validation: "",
      default: "",
      label: "Theme Text Color",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Secondary Color",
      name: "secondaryColor",
      validation: "",
      default: "",
      label: "Secondary Color",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Secondary Text Color",
      name: "secondaryTextColor",
      validation: "",
      default: "",
      label: "Secondary Text Color",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Tracking Code",
      name: "trackingCode",
      validation: "",
      default: "",
      label: "Tracking Code",
      required: false,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "multiSelect",
      apiType: "API",
      selectApi: "country/select",
      placeholder: "Allowed Countries",
      showItem: "title",
      name: "countries",
      validation: "",
      default: "",
      tag: true,
      label: "Countries",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "image",
      placeholder: "Mobile Banner",
      name: "mobBanner",
      validation: "",
      default: "false",
      tag: true,
      label: "Mobile Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Registration Banner",
      name: "regBanner",
      validation: "",
      default: "false",
      tag: true,
      label: "Registration Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Show Sub Event Page",
      name: "showSubEventPage",
      validation: "",
      default: null,
      tag: true,
      label: "Show Sub Event Page",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Sub Event Template",
      name: "subEventTemplate",
      condition: {
        item: "showSubEventPage",
        if: "true",
        then: "enabled",
        else: "disabled",
      },
      selectApi: "Theme1,Theme2,Theme3,Theme4",
      validation: "",
      default: "",
      label: "Sub Event Template",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Sub Page Banner",
      name: "subPageBanner",
      validation: "",
      default: "false",
      tag: true,
      label: "Sub Page Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Badge & Certificate",
      name: "Badge & Certificate",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Badge",
      name: "idCard",
      validation: "",
      default: null,
      tag: true,
      label: "Badge",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Badge Width",
      name: "idCardWidth",
      condition: {
        item: "idCard",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Badge Width",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Badge Height",
      name: "idCardHeight",
      condition: {
        item: "idCard",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Badge Height",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Certificate",
      name: "certificate",
      validation: "",
      default: null,
      tag: true,
      label: "Certificate",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Certificate Width",
      name: "certificateWidth",
      condition: {
        item: "certificate",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Certificate Width",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Certificate Height",
      name: "certificateHeight",
      condition: {
        item: "certificate",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Certificate Height",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Footer Settings",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Contact Number",
      name: "contactNumber",
      validation: "",
      default: "",
      label: "Contact Number",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Alternate Contact Number",
      name: "alternateContactNumber",
      validation: "",
      default: "",
      label: "Alternate Contact Number",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Email Id",
      name: "emailId",
      validation: "",
      default: "",
      label: "Email Id",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Website",
      name: "website",
      validation: "",
      default: "",
      label: "Website",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Office Address",
      name: "OfficeAddress",
      validation: "",
      default: "",
      label: "Office Address",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Corporate Office Address",
      name: "corporateOfficeAddress",
      validation: "",
      default: "",
      label: "Corporate Office Address",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Footer Logo",
      name: "footerLogo",
      validation: "",
      default: "false",
      tag: true,
      label: "Footer Logo",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Social Media  Configuration",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Facebook URL",
      name: "facebook",
      validation: "",
      default: "",
      label: "Facebook URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Insta URL",
      name: "insta",
      validation: "",
      default: "",
      label: "Insta URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "X URL",
      name: "xSocial",
      validation: "",
      default: "",
      label: "X URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Linkedin URL",
      name: "linkedin",
      validation: "",
      default: "",
      label: "Linkedin URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Youtube URL",
      name: "youtube",
      validation: "",
      default: "",
      label: "Youtube URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Whatsapp URL",
      name: "whatsapp",
      validation: "",
      default: "",
      label: "Whatsapp URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Sharechat URL",
      name: "sharechat",
      validation: "",
      default: "",
      label: "Sharechat URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Threads URL",
      name: "threads",
      validation: "",
      default: "",
      label: "Threads URL",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Email Template",
      name: "emailTemplate",
      validation: "",
      default: "",
      label: "Email Template",
      tag: false,
      required: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Whatsapp Template",
      name: "whatsappTemplate",
      validation: "",
      default: "",
      label: "Whatsapp Template",
      tag: false,
      required: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Abstract",
      name: "enableAbstract",
      validation: "",
      default: null,
      tag: true,
      label: "Enable Abstract",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [landingPageConfig] = useState([
    {
      type: "checkbox",
      placeholder: "Enable this Section",
      name: "status",
      validation: "",
      default: null,
      tag: true,
      label: "Enable this Section",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Configuration",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Page Section",
      name: "type",
      validation: "",
      default: "",
      tag: true,
      label: "Page Section",
      showItem: "Type",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "counts", value: "Counts" },
        { id: "about", value: "About" },
        { id: "speakers", value: "Speakers" },
        { id: "features", value: "Features" },
        { id: "keyfeatures", value: "Key Features" },
        { id: "countdown", value: "Countdown" },
        { id: "newsupdates", value: "News Updates" },
        { id: "testimonial", value: "Testimonial" },
        { id: "gallery", value: "Gallery" },
        { id: "socialmedia", value: "Social Media" },
        { id: "faq", value: "Faq" },
        { id: "video", value: "Video" },
        { id: "sponsors", value: "Sponsors" },
        { id: "footer", value: "Footer" },
        { id: "events", value: "Tickets" },
      ],
    },
    {
      type: "select",
      placeholder: "Orientation",
      name: "orientation",
      validation: "",
      default: "",
      label: "Orientation",
      showItem: "orientation",
      required: false,
      view: false,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Portrait,Landscape",
    },
    {
      type: "select",
      placeholder: "DeskTop Scrolling",
      name: "deskTopScrolling",
      validation: "",
      default: "",
      label: "DeskTop Scrolling",
      showItem: "deskTopScrolling",
      required: false,
      view: false,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Vertical,Horizontal",
    },
    {
      type: "select",
      placeholder: "Mobile Scrolling",
      name: "mobileScrolling",
      validation: "",
      default: "",
      label: "Mobile Scrolling",
      showItem: "mobileScrolling",
      required: false,
      view: false,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Vertical,Horizontal",
    },
    {
      type: "select",
      placeholder: "Direction Desktop",
      name: "directionDesktop",
      validation: "",
      default: "",
      label: "Direction Desktop",
      showItem: "directionDesktop",
      required: false,
      view: false,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Left,Right",
    },
    {
      type: "select",
      placeholder: "Direction Mobile",
      name: "directionMobile",
      validation: "",
      default: "",
      label: "Direction Mobile",
      showItem: "directionMobile",
      required: false,
      view: false,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Top,Bottom",
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Sequence",
      name: "sequence",
      validation: "",
      default: "",
      label: "Sequence",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Target date",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
      condition: {
        item: "type",
        if: "countdown",
        then: "enabled",
        else: "disabled",
      },
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      label: "Description",
      tag: true,
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Number Of Item To Show",
      name: "numberOfItemToShow",
      validation: "",
      default: "",
      label: "Number Of Item To Show",
      tag: true,
      required: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Show In Menu",
      name: "showInMenu",
      validation: "",
      default: null,
      tag: true,
      label: "Show In Menu",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Menu Title",
      name: "menuTitle",
      validation: "",
      default: "",
      label: "Menu Title",
      tag: true,
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Theme",
      name: "theme",
      validation: "",
      default: "",
      label: "Theme",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "BackgroundImage",
      name: "backgroundImage",
      validation: "",
      default: "",
      tag: true,
      label: "BackgroundImage",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Mobile Image",
      name: "mobileImage",
      validation: "",
      default: "",
      tag: true,
      label: "Mobile Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [whitelistedDomain] = useState([
    {
      type: "text",
      placeholder: "Domain",
      name: "domain",
      validation: "",
      default: "",
      label: "Domain",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Active",
      name: "status",
      validation: "",
      default: "",
      tag: true,
      label: "Active",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      tag: true,
      label: "Title",
      required: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Route",
      name: "route",
      validation: "",
      default: "",
      tag: true,
      label: "Route",
      showItem: "Route",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "event,admin",
    },
    {
      type: "image",
      placeholder: "Nav Icon",
      name: "navIcon",
      validation: "",
      default: "false",
      tag: true,
      label: "Nav Icon",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [instance] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Is Active",
      name: "isActive",
      validation: "",
      default: "",
      label: "Is Active",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    // {
    //   type: "date",
    //   placeholder: "Date",
    //   name: "date",
    //   validation: "",
    //   tag: true,
    //   label: "Date",
    //   required: false,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
  ]);

  const [registration] = useState([
    {
      type: "text",
      placeholder: "Unique ID",
      name: "_id",
      validation: "",
      default: "",
      label: "Unique ID",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "First Name",
      name: "firstName",
      validation: "",
      default: "",
      label: "First Name",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Last Name",
      name: "lastName",
      validation: "",
      default: "",
      label: "Last Name",
      tag: false,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Full Name",
      name: "fullName",
      validation: "",
      default: "",
      label: "Full Name",
      tag: false,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Authentication Type",
      name: "authenticationType",
      validation: "",
      default: "",
      tag: false,
      label: "Authentication Type",
      showItem: "Authentication Type",
      required: true,
      view: false,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "gmail,apple,Whatsapp,mobile,email",
    },
    {
      type: "text",
      placeholder: "Phone Code",
      name: "phoneCode",
      validation: "",
      default: "",
      label: "Phone Code",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Phone Number",
      name: "authenticationId",
      validation: "",
      default: "",
      label: "Phone Number",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Email Id",
      name: "emailId",
      collection: "formData",
      showItem: "emailId",
      validation: "",
      default: "",
      label: "Email Id",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Authenticated Token",
      name: "authenticatedToken",
      validation: "",
      default: "",
      label: "Authenticated Token",
      // tag: true,
      required: false,
      view: false,
      add: true,
      update: true,
      tag: false,
    },
    {
      type: "text",
      placeholder: "Session Token",
      name: "sessionToken",
      validation: "",
      default: "",
      label: "Session Token",
      // tag: true,
      required: false,
      view: false,
      add: true,
      update: true,
      tag: false,
    },
    {
      type: "checkbox",
      placeholder: "Is Verified",
      name: "isVerified",
      validation: "",
      default: null,
      tag: true,
      label: "Is Verified",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      placeholder: "Is Verified",
      name: "isVerified",
      validation: "",
      default: "",
      tag: false,
      label: "Is Verified",
      showItem: "Is Verified",
      required: true,
      view: false,
      filter: true,
      add: false,
      update: false,
      apiType: "JSON",
      selectApi: [
        { id: 1, value: "Verified" },
        { id: 0, value: "OTP Send" },
      ],
    },
    // {
    //   type: "text",
    //   placeholder: "One Time Password",
    //   name: "oneTimePassword",
    //   validation: "",
    //   default: "",
    //   label: "One Time Password",
    //   tag: true,
    //   required: true,
    //   view: true,
    //   add: true,
    //   update: true,
    // },
    {
      type: "select",
      apiType: "API",
      selectApi: "franchise/select",
      placeholder: "Franchise",
      name: "franchise",
      validation: "",
      showItem: "name",
      default: "",
      label: "Franchise",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
  ]);

  const [attendance] = useState([
    {
      type: "text",
      placeholder: "User",
      name: "fullName",
      collection: "user",
      showItem: "fullName",
      validation: "",
      default: "",
      tag: false,
      label: "User",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "text",
      placeholder: "Mobile Number",
      name: "authenticationId",
      collection: "user",
      showItem: "authenticationId",
      validation: "",
      default: "",
      tag: true,
      label: "Mobile Number",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "checkbox",
      placeholder: "Is Verified",
      name: "isVerified",
      collection: "user",
      showItem: "isVerified",
      validation: "",
      default: null,
      tag: true,
      label: "Is Verified",
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "datetime",
      placeholder: "Date",
      name: "createdAt",
      validation: "",
      default: "",
      label: "Date",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [eventAdmin] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      tag: true,
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "email",
      placeholder: "E-Mail",
      name: "email",
      validation: "",
      default: "",
      tag: true,
      label: "E-Mail",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Password",
      name: "password",
      validation: "password ",
      default: "",
      // tag: true,
      label: "Password",
      required: true,
      view: true,
      add: true,
      update: false,
    },
  ]);

  const [ticketAdmin] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      tag: true,
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "email",
      placeholder: "E-Mail",
      name: "email",
      validation: "",
      default: "",
      tag: true,
      label: "E-Mail",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Password",
      name: "password",
      validation: "password ",
      default: "",
      // tag: true,
      label: "Password",
      required: true,
      view: true,
      add: true,
      update: false,
    },
  ]);

  const [paymentHistory] = useState([
    {
      type: "text",
      placeholder: "User",
      name: "authentication",
      collection: "authentication",
      validation: "",
      showItem: "fullName",
      default: "",
      tag: false,
      label: "User",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Mobile",
      name: "authentication",
      collection: "authentication",
      validation: "",
      showItem: "authenticationId",
      default: "",
      tag: true,
      label: "Mobile",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Ticket",
      name: "ticket",
      collection: "ticket",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Ticket",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Event",
      name: "event",
      collection: "event",
      validation: "",
      showItem: "title",
      default: "",
      tag: true,
      label: "Event",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Razorpay Order Id",
      name: "razorpayOrderId",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Razorpay Order Id",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Razorpay Payment Id",
      name: "razorpayPaymentId",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Razorpay Payment Id",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "number",
      placeholder: "Amount",
      name: "amount",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Amount",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Currency",
      name: "currency",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Currency",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Status",
      name: "status",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Status",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
  ]);

  // const [websiteContent] = useState([]);

  const [collection] = useState([
    {
      type: "text",
      placeholder: "Page",
      name: "page",
      validation: "",
      default: "",
      label: "Page",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Content",
      name: "content",
      validation: "",
      default: "",
      label: "Content",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Slug",
      name: "slug",
      validation: "",
      default: "",
      label: "Slug",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Menu Title",
      name: "menuTitle",
      validation: "",
      default: "",
      label: "Menu Title",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "false",
      tag: true,
      label: "Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [count] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "count/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Count (eg:200+)",
      name: "count",
      validation: "",
      default: "",
      label: "Count (eg:200+)",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [gallery] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "gallery/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "false",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [news] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "news/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      label: "Description",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Date",
      name: "date",
      validation: "",
      default: "",
      label: "Date",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "false",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [speakers] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "speakers/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      label: "Name",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Designation",
      name: "designation",
      validation: "",
      default: "",
      label: "Designation",
      tag: true,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "photo",
      validation: "",
      default: "false",
      tag: true,
      label: "Image",
      // required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [sponsors] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "sponsors/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Sequence",
      name: "sequence",
      validation: "",
      default: "",
      label: "Sequence",
      tag: true,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Logo",
      name: "logo",
      validation: "",
      default: "false",
      tag: true,
      label: "Logo",
      // required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [features] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "features/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      placeholder: "Type",
      name: "type",
      validation: "",
      default: "",
      label: "Type",
      showItem: "",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "features,keyfeatures",
    },
    {
      type: "number",
      placeholder: "Sequence",
      name: "sequence",
      validation: "",
      default: "",
      label: "Sequence",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      label: "Description",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Icon",
      name: "icon",
      validation: "",
      default: "false",
      tag: true,
      label: "Icon",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [testimonial] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "testimonial/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Content",
      name: "content",
      validation: "",
      default: "",
      label: "Content",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Image",
      name: "image",
      validation: "",
      default: "false",
      tag: true,
      label: "Image",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Author",
      name: "author",
      validation: "",
      default: "",
      label: "Author",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Designation",
      name: "designation",
      validation: "",
      default: "",
      label: "Designation",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [additionalMenus] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Icon",
      name: "icon",
      validation: "",
      default: "",
      label: "Icon",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Sequence",
      name: "sequence",
      validation: "",
      default: "",
      label: "Sequence",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Placement Type",
      name: "placement",
      validation: "",
      default: "",
      tag: true,
      label: "Placement Type",
      showItem: "",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "header,footer,both",
    },
    {
      type: "select",
      placeholder: "Target Type",
      name: "targetType",
      validation: "",
      default: "External Url",
      tag: true,
      label: "Target Type",
      showItem: "",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "External Url,IFrame,Events Page,Custom Content Page,Home",
    },
    {
      type: "text",
      placeholder: "Url",
      name: "url",
      condition: {
        item: "targetType",
        if: ["External Url", "IFrame"],
        then: "enabled",
        else: "disabled",
      },
      validation: "url",
      default: "",
      label: "Url",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Slug",
      name: "slug",
      condition: {
        item: "targetType",
        if: ["Custom Content Page", "IFrame", "Events Page", "External Url"],
        then: "enabled",
        else: "disabled",
      },
      validation: "slug",
      default: "",
      label: "Slug",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Content",
      condition: {
        item: "targetType",
        if: "Custom Content Page",
        then: "enabled",
        else: "disabled",
      },
      name: "pageData",
      validation: "",
      default: "",
      tag: false,
      label: "Content",
      required: false,
      view: false,
      add: true,
      update: true,
    },
  ]);

  const [faq] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "faq/selectBytype",
      placeholder: "Segment",
      name: "segment",
      validation: "",
      showItem: "",
      default: "",
      tag: true,
      label: "Segment",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Question",
      name: "question",
      validation: "",
      default: "",
      label: "Question",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Answer",
      name: "answer",
      validation: "",
      default: "",
      label: "Answer",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [settings] = useState([
    {
      type: "title",
      title: "Whatsapp Configuration",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "CSV",
      placeholder: "Whatsapp Account Provider",
      name: "whatsappAccountProvider",
      selectApi: "Dxing,Alerts Panel,Official API",
      validation: "",
      default: "",
      label: "Whatsapp Account Provider",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Whatsapp Url",
      name: "whatsappUrl",
      validation: "",
      default: "",
      label: "Whatsapp Url",
      view: false,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Whatsapp Access Token",
      name: "whatsappAccessToken",
      validation: "",
      default: "",
      label: "Whatsapp Access Token",
      tag: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Whatsapp Account",
      name: "whatsappAccount",
      validation: "",
      default: "",
      label: "Whatsapp Account",
      tag: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Email Configuration",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "SMTP HOST",
      name: "smtpHost",
      validation: "",
      default: "",
      label: "SMTP HOST",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "SMTP PORT",
      name: "smtpPort",
      validation: "",
      default: "",
      label: "SMTP PORT",
      tag: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "SMTP USERNAME",
      name: "smtpUsername",
      validation: "",
      default: "",
      label: "SMTP USERNAME",
      tag: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "SMTP PASSWORD",
      name: "smtpPassword",
      validation: "",
      default: "",
      label: "SMTP PASSWORD",
      tag: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "SMTP FROM EMAIL",
      name: "smtpFromEmail",
      validation: "",
      default: "",
      label: "SMTP FROM EMAIL",
      tag: true,
      view: false,
      add: true,
      update: true,
    },
    // {
    //   type: "select",
    //   apiType: "API",
    //   selectApi: "country/select",
    //   placeholder: "Country",
    //   name: "country",
    //   validation: "",
    //   default: "",
    //   tag: true,
    //   label: "Country",
    //   required: false,
    //   view: true,
    //   add: true,
    //   update: true,
    //   filter: true,
    // },
    {
      type: "multiSelect",
      apiType: "API",
      selectApi: "country/select",
      placeholder: "Allowed Countries",
      showItem: "title",
      name: "countries",
      validation: "",
      default: "",
      tag: true,
      label: "Countries",
      required: false,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "title",
      title: "Payment Configuration",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Payment Gateway",
      name: "paymentGateway",
      validation: "",
      default: "Razorpay",
      tag: true,
      label: "Payment Gateway",
      showItem: "",
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Razorpay",
    },
    {
      type: "password",
      placeholder: "Payment Gateway Key",
      name: "paymentGatewayKey",
      validation: "",
      default: "",
      label: "Payment Gateway Key",
      tag: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "password",
      placeholder: "Payment Gateway Secret",
      name: "paymentGatewaySecret",
      validation: "",
      default: "",
      label: "Payment Gateway Secret",
      tag: false,
      view: false,
      add: true,
      update: true,
    },
  ]);

  const [bulkMsg] = useState([
    {
      type: "textarea",
      placeholder: "Whatsapp Template",
      name: "whatsappTemplate",
      validation: "",
      default: "",
      label: "Whatsapp Template",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "file",
      placeholder: "Attachment",
      name: "attachment",
      validation: "",
      default: "",
      label: "Attachment",
      tag: true,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "bulkMsg/ticket/select",
      placeholder: "Ticket",
      collection: "event",
      name: "ticket",
      validation: "",
      showItem: "name",
      default: "",
      label: "Ticket",
      // required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
  ]);

  const [ticket] = useState([
    {
      type: "checkbox",
      placeholder: "Enable This Ticket",
      name: "status",
      validation: "",
      default: null,
      tag: true,
      label: "Enable This Ticket",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Abstract",
      name: "enableAbstract",
      validation: "",
      default: null,
      tag: true,
      label: "Enable Abstract",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Configuration",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Attendances",
      name: "attendanceCount",
      validation: "",
      default: 0,
      label: "Attendances",
      tag: true,
      required: false,
      view: true,
      add: false,
      update: false,
    },
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Short Description",
      name: "shortDescription",
      validation: "",
      default: "",
      label: "Short Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Description",
      name: "description",
      validation: "",
      default: "",
      label: "Description",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Booking Count",
      name: "bookingCount",
      validation: "",
      default: null,
      tag: true,
      label: "Booking Count",
      required: false,
      view: true,
      add: false,
      update: false,
    },

    {
      type: "datetime",
      placeholder: "Start Date",
      name: "startDate",
      validation: "",
      default: "",
      tag: true,
      label: "Start Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "End Date",
      name: "endDate",
      validation: "",
      default: "",
      tag: true,
      label: "End Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Registration Start Date",
      name: "registrationStartDate",
      validation: "",
      default: "",
      tag: true,
      label: "Start Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Registration End Date",
      name: "registrationEndDate",
      validation: "",
      default: "",
      tag: true,
      label: "End Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Duration",
      name: "duration",
      validation: "",
      default: "",
      tag: true,
      label: "Duration",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Venue",
      name: "venue",
      validation: "",
      default: "",
      tag: true,
      label: "Venue",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Slug",
      name: "slug",
      validation: "slug",
      default: "",
      tag: true,
      label: "slug",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Tag",
      name: "tag",
      validation: "",
      default: "",
      label: "Tag",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Thumbnail Image",
      name: "thumbnail",
      validation: "",
      default: "",
      tag: true,
      label: "Thumbnail Image",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Banner",
      name: "banner",
      validation: "",
      default: "",
      tag: true,
      label: "Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "idCardBackground",
      name: "idCardBackground",
      validation: "",
      default: "",
      tag: true,
      label: "idCardBackground",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Category",
      name: "category",
      validation: "",
      default: "",
      tag: true,
      label: "Category",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Content",
      name: "content",
      validation: "",
      default: "",
      tag: false,
      label: "Content",
      required: false,
      view: false,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Direct Registration",
      name: "enableDirectRegistration",
      validation: "",
      default: false,
      tag: true,
      label: "Enable Direct Registration",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Override `Name Field` Label",
      name: "overrideYourNameField",
      validation: "",
      condition: {
        item: "enableDirectRegistration",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Override `Name Field` Label",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Override `Name Field` Text Format",
      name: "overrideYourNameValidation",
      validation: "",
      condition: {
        item: "enableDirectRegistration",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Override `Name Field` Text Format",
      required: false,
      view: true,
      add: true,
      apiType: "JSON",
      selectApi: [
        { id: "", value: "Any Case" },
        { id: "uppercase", value: "Upper Case" },
        { id: "lowercase", value: "Lower Case" },
        { id: "camelcase", value: "Camel Case" },
        { id: "propercase", value: "Proper Case" },
      ],
      update: true,
    },
    {
      type: "text",
      placeholder: "Override `Name Field` Info",
      name: "overrideYourNameInfo",
      validation: "",
      condition: {
        item: "enableDirectRegistration",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Override `Name Field` Info",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Registration",
      name: "enableRegistration",
      validation: "",
      default: true,
      tag: true,
      label: "Enable Registration",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Coupon Code",
      name: "enableCoupenCode",
      validation: "",
      default: false,
      tag: true,
      label: "Enable Coupon Code",
      required: false,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "checkbox",
      placeholder: "ID Card Required",
      name: "idCardRequired",
      validation: "",
      default: null,
      tag: true,
      label: "ID Card Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Registration Required",
      name: "registrationRequired",
      validation: "",
      default: null,
      tag: true,
      label: "Registration Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Registration Notification Required",
      name: "registrationNotificationRequired",
      validation: "",
      default: null,
      tag: true,
      label: "Registration Notification Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Detail Page Required",
      name: "detailPageRequired",
      validation: "",
      default: null,
      tag: true,
      label: "Detail Page Required",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Registration Setting",
      name: "RegistrationSetting",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Maximum Registrations",
      name: "maximumRegistrations",
      validation: "",
      default: 0,
      label: "Maximum Registrations",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Registration Expiry Date",
      name: "registrationExpiryDate",
      validation: "",
      default: "",
      label: "Registration Expiry Date",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Multiple Entry Allowed",
      name: "isMultipleEntry",
      validation: "",
      default: null,
      tag: true,
      label: "Multiple Entry Allowed",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Multiple Entri Limit",
      name: "multipleEntriLimit",
      validation: "",
      default: 1,
      label: "Multiple Entri Limit",
      required: true,
      view: true,
      add: true,
      update: true,
      condition: {
        item: "isMultipleEntry",
        if: true,
        then: "enabled",
        else: "disabled",
      },
    },
    {
      type: "select",
      placeholder: "Form Mode",
      name: "formMode",
      validation: "",
      default: "disabled",
      apiType: "JSON",
      selectApi: [
        { id: "single", value: "Single Column" },
        { id: "double", value: "Double Column" },
      ],
      label: "Form Mode",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Ticketing",
      name: "Ticketing",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Group Ticketing",
      name: "groupTicketing",
      validation: "",
      default: null,
      tag: true,
      label: "Group Ticketing",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Paymet",
      name: "enablePayment",
      validation: "",
      default: true,
      tag: true,
      label: "Enable Paymet",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Amount Per Ticket",
      name: "paymentAmount",
      validation: "",
      condition: {
        item: "enablePayment",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Amount Per Ticket",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Tax Percentage",
      name: "taxPerentage",
      validation: "",
      condition: {
        item: "enablePayment",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Tax Percentage",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Including Tax",
      name: "includingTax",
      validation: "",
      condition: {
        item: "enablePayment",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Including Tax",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Currency",
      name: "currency",
      validation: "",
      condition: {
        item: "enablePayment",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      default: true,
      tag: true,
      label: "Currency",
      apiType: "CSV",
      selectApi: "INR",
      required: false,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "title",
      title: "Badge & Certificate",
      name: "Badge & Certificate",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Badge",
      name: "idCard",
      validation: "",
      default: null,
      tag: true,
      label: "Badge",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Badge Width",
      name: "idCardWidth",
      condition: {
        item: "idCard",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Badge Width",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Badge Height",
      name: "idCardHeight",
      condition: {
        item: "idCard",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Badge Height",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Certificate",
      name: "certificate",
      validation: "",
      default: null,
      tag: true,
      label: "Certificate",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Certificate Width",
      name: "certificateWidth",
      condition: {
        item: "certificate",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Certificate Width",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Certificate Height",
      name: "certificateHeight",
      condition: {
        item: "certificate",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Certificate Height",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "On Successfull Message",
      name: "OnSuccessfullMessage",
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "On Successfull Message",
      name: "onsuccessfullMessage",
      validation: "",
      default: "",
      label: "On Successfull Message",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Email Template",
      name: "emailTemplate",
      validation: "",
      default: "",
      label: "Email Template",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Whatsapp Template",
      name: "whatsappTemplate",
      validation: "",
      default: "",
      label: "Whatsapp Template",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "On Confirmation Message",
      name: "OnSuccessfullMessage",
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "On Confirmation Message",
      name: "onConfirmationMessage",
      validation: "",
      default: "",
      label: "On Confirmation Message",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "htmleditor",
      placeholder: "Confirmation Email Template",
      name: "onConfirmationEmailTemplate",
      validation: "",
      default: "",
      label: "Confirmation Email Template",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Confirmation Whatsapp Template",
      name: "onConfirmationWhatsappTemplate",
      validation: "",
      default: "",
      label: "Confirmation Whatsapp Template",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [ticketFormDataVisibilityCondition] = useState([
    {
      type: "text",
      placeholder: "Condition Checking Field",
      name: "whenField",
      validation: "",
      default: "",
      label: "Condition Checking Field",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Match Values",
      name: "matchValues",
      validation: "",
      default: "",
      label: "Match Values",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Match Criteria",
      name: "matchCriteria",
      validation: "",
      default: "",
      apiType: "JSON",
      selectApi: [
        { id: "match", value: "Match" },
        { id: "not match", value: "Not match" },
        { id: "contains", value: "Contains" },
        { id: "does not contain", value: "Does not contain" },
      ],
      label: "Match Criteria",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "If Match Display",
      name: "ifMatchDisplay",
      validation: "",
      default: "disabled",
      apiType: "JSON",
      selectApi: [
        { id: "enabled", value: "Show This Field" },
        { id: "disabled", value: "Hide This Field" },
      ],
      label: "If Match Display",
      tag: true,
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [ticketFormData] = useState([
    {
      type: "select",
      placeholder: "Type",
      name: "type",
      validation: "",
      default: "",
      tag: true,
      label: "Type",
      showItem: "Type",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "JSON",
      selectApi: [
        { id: "select", value: "Select" },
        { id: "multiSelect", value: "MultiSelect" },
        { id: "text", value: "Text" },
        { id: "textarea", value: "Textarea" },
        { id: "image", value: "Image" },
        { id: "checkbox", value: "Checkbox" },
        { id: "date", value: "Date" },
        { id: "datetime", value: "DateTime" },
        { id: "number", value: "Number" },
        { id: "email", value: "Email" },
        { id: "password", value: "Password" },
        { id: "mobileNumber", value: "Mobile Number" },
      ],
    },
    {
      type: "text",
      placeholder: "Label",
      name: "label",
      validation: "",
      default: "",
      label: "Label",
      tag: false,
      required: true,
      view: true,
      add: true,
      update: true,
      onChange: onChange,
    },
    {
      type: "hidden",
      placeholder: "Place Holder",
      name: "placeHolder",
      validation: "",
      default: "",
      label: "Place Holder",
      tag: false,
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "number",
      placeholder: "Order Id",
      name: "orderId",
      validation: "",
      default: "",
      label: "Order Id",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Api Type",
      name: "apiType",
      condition: {
        item: "type",
        if: ["select", "multiSelect"],
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: false,
      label: "Api Type",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "CSV",
    },

    {
      type: "textarea",
      placeholder: "Add options",
      name: "selectApi",
      condition: {
        item: "type",
        if: "select",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: false,
      label: "Add options",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Select Api",
      name: "selectApi",
      condition: {
        item: "type",
        if: "multiSelect",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: false,
      label: "Select Api",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Validation",
      name: "validation",
      validation: "",
      default: "",
      label: "Validation",
      tag: false,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "hidden",
      placeholder: "Collection",
      name: "dbcollection",
      validation: "",
      default: "formData",
      label: "Collection",
      tag: false,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "hidden",
      placeholder: "Show Item",
      name: "showItem",
      validation: "",
      default: "",
      label: "Show Item",
      tag: false,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Default",
      name: "default",
      validation: "",
      default: "",
      label: "Default",
      tag: false,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Permission Settings",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "hidden",
      placeholder: "Tag",
      name: "tag",
      validation: "",
      default: "true",
      value: true,
      tag: false,
      label: "Tag",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Required",
      name: "required",
      validation: "",
      default: "true",
      tag: true,
      label: "Required",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "hidden",
      value: true,
      placeholder: "View",
      name: "view",
      validation: "",
      tag: false,
      label: "View",
      required: true,
      view: true,
      add: true,
      update: true,
      default: "true",
    },
    {
      type: "hidden",
      placeholder: "Add",
      value: true,
      name: "add",
      validation: "",
      tag: false,
      label: "Add",
      required: true,
      view: true,
      add: true,
      update: true,
      default: "true",
    },
    {
      type: "hidden",
      value: true,
      placeholder: "Update",
      name: "update",
      validation: "",
      tag: false,
      label: "Update",
      required: true,
      view: true,
      add: true,
      update: true,
      default: "true",
    },
    {
      type: "hidden",
      placeholder: "Filter",
      value: true,
      name: "filter",
      validation: "",
      tag: false,
      label: "Filter",
      required: true,
      view: true,
      add: true,
      update: true,
      default: "true",
    },
    {
      type: "title",
      title: "Condition Settings",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Enable Condition",
      name: "conditionEnabled",
      validation: "",
      default: "false",
      tag: true,
      label: "Enable Condition",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Condition Checking Field",
      name: "conditionWhenField",
      condition: {
        item: "conditionEnabled",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      validation: "",
      default: "",
      label: "Condition Checking Field",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Match Values",
      name: "conditionCheckMatch",
      condition: {
        item: "conditionEnabled",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      validation: "",
      default: "",
      label: "Match Values",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "If Match",
      name: "conditionIfMatch",
      condition: {
        item: "conditionEnabled",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      validation: "",
      default: "",
      apiType: "JSON",
      selectApi: [
        { id: "enabled", value: "Show This Filed" },
        { id: "disabled", value: "Hide This Filed" },
      ],
      label: "Check Match Values",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [ticketCoupen] = useState([
    {
      type: "text",
      placeholder: "Coupon Code",
      name: "code",
      validation: "",
      default: "",
      label: "Coupon Code",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Discount Type",
      name: "type",
      validation: "",
      default: "",
      label: "Discount Type",
      showItem: "Discount Type",
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "percentage,fixed",
    },
    {
      type: "number",
      placeholder: "Discount  Value",
      name: "value",
      validation: "",
      default: "",
      label: "Discount  Value",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "datetime",
      placeholder: "Valid From",
      name: "startDate",
      validation: "",
      default: "",
      label: "Valid From",
      tag: false,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Valid To",
      name: "endDate",
      validation: "",
      default: "",
      label: "Valid To",
      tag: false,
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "number",
      placeholder: "Max Uses",
      name: "usageLimit",
      validation: "",
      default: "",
      label: "Max Uses",
      tag: false,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Maximum Discount",
      name: "maxDiscount",
      validation: "",
      default: "",
      label: "Maximum Discount",
      tag: false,
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Status",
      name: "isActive",
      validation: "",
      default: null,
      tag: true,
      label: "Status",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [luckyDraw] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "firstName",
      validation: "",
      default: "",
      label: "Name",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Place",
      name: "yourPlace",
      validation: "",
      default: "",
      label: "Place",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "PhoneCode",
      name: "phoneCode",
      validation: "",
      default: "",
      label: "PhoneCode",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "ContactNumber",
      name: "contactNumber",
      validation: "",
      default: "",
      label: "ContactNumber",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "EmailId",
      name: "emailId",
      validation: "",
      default: "",
      label: "EmailId",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "QTicketNumber",
      name: "qTicketNumber",
      validation: "",
      default: "",
      label: "QTicketNumber",
      tag: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Date",
      name: "createdAt",
      validation: "",
      default: "",
      label: "Date",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [certificationData] = useState([
    {
      type: "select",
      placeholder: "Type",
      name: "type",
      validation: "",
      default: "",
      label: "Type",
      showItem: "Type",
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "badge,certificate",
    },
    {
      type: "select",
      placeholder: "Layer Type",
      name: "layerType",
      validation: "",
      default: "",
      label: "Layer Type",
      showItem: "Layer Type",
      tag: true,
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "BackgroundImage,BackgroundGradient,Text,Image,Qr",
    },
    {
      type: "text",
      placeholder: "Height",
      name: "height",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Height",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Width",
      name: "width",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Width",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Sequence",
      name: "sequence",
      showItem: "",
      validation: "",
      default: "0",
      tag: true,
      label: "Sequence",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Sample",
      name: "sample",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Sample",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Text Colour",
      name: "textColour",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Text Colour",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "BackgroundImageUrl",
      name: "source",
      validation: "",
      condition: {
        item: "layerType",
        if: "BackgroundImage",
        then: "enabled",
        else: "disabled",
      },
      default: "false",
      tag: true,
      label: "BackgroundImageUrl",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Position",
      name: "position",
      condition: {
        item: "layerType",
        if: "BackgroundImage",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Position",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "cover,contain",
    },
    {
      type: "select",
      placeholder: "Source",
      name: "source",
      condition: {
        item: "layerType",
        if: "BackgroundGradient",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Source",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "ColourPicker",
    },
    {
      type: "select",
      apiType: "API",
      placeholder: "Source",
      name: "source",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      params: [{ name: "ticket" }],
      validation: "",
      default: "",
      tag: true,
      label: "Source",
      required: false,
      view: true,
      add: true,
      update: true,
      selectApi: "ticket-form-data/select",
    },
    {
      type: "text",
      placeholder: "Font",
      name: "font",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Font",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Font Size",
      name: "fontSize",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Font Size",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Font Style",
      name: "fontStyle",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Font Style",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Bold,Italic,Underline,Normal",
    },
    {
      type: "select",
      placeholder: "Alignment",
      name: "textAlignment",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Alignment",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Left,Right,Justify,Center",
    },
    {
      type: "text",
      placeholder: "Top",
      name: "top",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Top",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Bottom",
      name: "bottom",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Bottom",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Right",
      name: "right",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Right",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Left",
      name: "left",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "auto",
      tag: true,
      label: "Left",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Alignment",
      name: "textAlignment",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Alignment",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Left,Right,Justify,Center",
    },
    {
      type: "text",
      placeholder: "Top",
      name: "top",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Top",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Bottom",
      name: "bottom",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Bottom",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Left",
      name: "left",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "auto",
      tag: true,
      label: "Left",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Right",
      name: "right",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Right",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Position",
      name: "position",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Position",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "cover,contain",
    },
    {
      type: "select",
      placeholder: "Shape",
      name: "shape",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Shape",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "round,square,curved",
    },
    {
      type: "image",
      placeholder: "Image Url",
      name: "source",
      validation: "",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      default: "false",
      tag: true,
      label: "Image Url",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Alignment",
      name: "textAlignment",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Alignment",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Left,Right,Justify,Center",
    },
    {
      type: "text",
      placeholder: "Top",
      name: "top",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Top",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Bottom",
      name: "bottom",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Bottom",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Left",
      name: "left",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "auto",
      tag: true,
      label: "Left",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Right",
      name: "right",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Right",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Source",
      name: "source",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Source",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "FieldName",
    },
    {
      type: "text",
      placeholder: "Prefix",
      name: "prefix",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Prefix",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Suffix",
      name: "suffix",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Suffix",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [actions] = useState([
    {
      element: "button",
      type: "subList",
      id: "authentication",
      title: "Registration",
      icon: "registration",
      attributes: registration,
      params: {
        api: `authentication`,
        parentReference: "event",
        icon: "registration",
        itemTitle: {
          name: "fullName",
          type: "text",
          collection: "",
        },
        actions: [
          // {
          //   element: "button",
          //   type: "callback",
          //   callback: (item, data) => {
          //     const PhoneNumber = `${data.phoneCode}${data.authenticationId}`;
          //     // const textMessage = `Click the below link to get your ID card,\n\n${
          //     //   data.event === "6631cfbc81facf0550dd9533"
          //         // ? "https://cokuae.com"
          //     //     : "https://admin.eventhex.co/"
          //     // }/my-id-card/${data.event}/${data._id}\n\nRegards`;
          //     const textMessage = `Click the below link to get your ID card,\n\n${
          //       data.event === "6631cfbc81facf0550dd9533"
          //         ? "https://cokuae.com"
          //         : "https://keddaexpo.eventhex.co"
          //     }/my-id-card/${data.event}/${data._id}\n\nRegards`;
          //     const encodedMessage = encodeURIComponent(textMessage);

          //     navigator.clipboard
          //       .writeText(textMessage)
          //       .then(() => {
          //         console.log("Link copied to clipboard");
          //       })
          //       .catch((err) => {
          //         console.error("Could not copy text: ", err);
          //       });

          //     //const whatsappURL = `https://web.whatsapp.com/send?phone=${PhoneNumber}&text=${encodedMessage}&type=phone_number&app_absent=0`;
          //     window.location.href = `whatsapp://send?phone=${PhoneNumber}&text=${encodedMessage}`;
          //   },

          //   itemTitle: {
          //     name: "fullName",
          //     type: "text",
          //     collection: "meal",
          //   },
          //   icon: "menu",
          //   title: "Send ID Card Link",
          //   params: {
          //     api: `food-group-item`,
          //     parentReference: "",
          //     itemTitle: {
          //       name: "mealName",
          //       type: "text",
          //       collection: "meal",
          //     },
          //     shortName: "Send ID Card Link",
          //     addPrivilege: true,
          //     delPrivilege: true,
          //     updatePrivilege: true,
          //     customClass: "medium",
          //   },
          // },
          {
            element: "button",
            type: "callback",
            callback: (item, data, refreshView) => {
              // Set the data for the clicked item and open the SetupMenu popup
              console.log({ data });
              // setUserId(data._id)
              getApproved(data._id, refreshView);
            },
            itemTitle: {
              name: "user",
              type: "text",
              collection: "",
            },
            icon: "menu",
            title: "Send ID Card Link",
            params: {
              api: ``,
              parentReference: "",
              itemTitle: {
                name: "user",
                type: "text",
                collection: "",
              },
              shortName: "Send ID Card Link",
              addPrivilege: true,
              delPrivilege: true,
              updatePrivilege: true,
              customClass: "medium",
            },
          },
        ],
        shortName: "Registration",
        addPrivilege: false,
        delPrivilege: true,
        updatePrivilege: true,
        exportPrivilege: true,
        customClass: "medium",
        formMode: "double",
      },
    },
    {
      element: "button",
      type: "subList",
      id: "ticket",
      title: "Ticket",
      icon: "ticket",
      attributes: ticket,
      params: {
        api: `ticket`,
        parentReference: "event",
        icon: "ticket",
        itemTitle: { name: "title", type: "text", collection: "" },
        shortName: "Ticket",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        showInfoType: "view",
        customClass: "medium",
        formMode: `double`,
        popupMode: "full-page",
        popupMenu: "vertical-menu",
        actions: [
          {
            element: "button",
            type: "custom",
            id: "ticket-registration",
            icon: "ticket-registration",
            title: "Ticket Registration",
            content: registrationFormData,
          },
          {
            element: "button",
            type: "subList",
            id: "ticket-form-data",
            itemTitle: "orderId",
            title: "Ticket Form",
            attributes: ticketFormData,
            params: {
              api: `ticket-form-data`,
              parentReference: "ticket",
              icon: "ticket-form-data",
              itemTitle: {
                name: "name",
                type: "text",
                collection: "",
              },
              shortName: "Ticket Form Data",
              addPrivilege: true,
              delPrivilege: true,
              updatePrivilege: true,
              customClass: "medium",
              formMode: `double`,
              actions: [
                {
                  element: "button",
                  type: "subList",
                  id: "form-input-visibility-condtions",
                  itemTitle: "whenField",
                  title: "Visibility Condtions",
                  attributes: ticketFormDataVisibilityCondition,
                  params: {
                    api: `form-input-visibility-condtions`,
                    parentReference: "ticketFormData",
                    icon: "display",
                    itemTitle: {
                      name: "whenField",
                      type: "text",
                      collection: "",
                    },
                    shortName: "Visibility Condtions",
                    addPrivilege: true,
                    delPrivilege: true,
                    updatePrivilege: true,
                    customClass: "medium",
                    formMode: `double`,
                  },
                },
              ],
            },
          },
          {
            element: "button",
            type: "subList",
            id: "attendance",
            itemTitle: "Attendance",
            title: "Attendance",
            attributes: attendance,
            params: {
              api: `attendance`,
              parentReference: "ticket",
              icon: "user",
              itemTitle: {
                name: "fullName",
                type: "text",
                collection: "user",
              },
              shortName: "Attendance",
              addPrivilege: false,
              delPrivilege: false,
              updatePrivilege: false,
              exportPrivilege: true,
              customClass: "medium",
              formMode: `double`,
            },
          },
          {
            element: "button",
            type: "subList",
            id: "instance",
            title: "Instance",
            icon: "instance",
            attributes: instance,
            params: {
              api: `instance`,
              parentReference: "ticket",
              icon: "instance",
              itemTitle: { name: "title", type: "text", collection: "" },
              shortName: "Instance",
              addPrivilege: true,
              delPrivilege: true,
              updatePrivilege: true,
              customClass: "medium",
              actions: [
                {
                  element: "button",
                  type: "subList",
                  id: "attendance",
                  title: "Attendance",
                  attributes: attendance,
                  params: {
                    api: `attendance`,
                    parentReference: "instance",
                    itemTitle: {
                      name: "fullName",
                      type: "text",
                      collection: "user",
                    },
                    shortName: "Attendance",
                    addPrivilege: false,
                    delPrivilege: false,
                    exportPrivilege: true,
                    updatePrivilege: false,
                    customClass: "medium",
                  },
                },
              ],
            },
          },
          {
            element: "button",
            type: "custom",
            id: "ticket-form-demo",
            // icon: "ticket",
            viewMode: "full-screen",
            title: "Ticket Form Demo",
            content: ticketForm,
          },
          {
            element: "button",
            type: "subList",
            id: "certification-data",
            // itemTitle: "type",
            title: "Certification Data",
            viewMode: "full-screen",
            // content: certificateFormData,
            attributes: certificationData,
            params: {
              api: `certification-data`,
              parentReference: "ticket",
              icon: "certification",
              itemTitle: {
                name: "type",
                type: "text",
                collection: "",
              },
              shortName: "Certification Data",
              addPrivilege: true,
              delPrivilege: true,
              updatePrivilege: true,
              customClass: "medium",
              formMode: `double`,
            },
          },
          {
            element: "button",
            type: "subList",
            id: "ticket-admin",
            itemTitle: "orderId",
            title: "Ticket Admin",
            attributes: ticketAdmin,
            params: {
              api: `user/ticket-admin`,
              parentReference: "ticket",
              icon: "ticket-admin",
              itemTitle: {
                name: "name",
                type: "text",
                collection: "",
              },
              shortName: "Ticket Admin",
              addPrivilege: true,
              delPrivilege: true,
              updatePrivilege: true,
              customClass: "medium",
              formMode: `double`,
            },
          },
          {
            element: "button",
            type: "subList",
            id: "coupen",
            itemTitle: "code",
            title: "Coupen",
            attributes: ticketCoupen,
            params: {
              api: `coupen`,
              parentReference: "ticket",
              icon: "coupen",
              itemTitle: {
                name: "code",
                type: "text",
                collection: "",
              },
              shortName: "coupon",
              addPrivilege: true,
              delPrivilege: true,
              updatePrivilege: true,
              customClass: "medium",
              formMode: `double`,
            },
          },
          {
            element: "button",
            type: "subList",
            id: "payment-history",
            itemTitle: "paymentId",
            title: "Payment History",
            attributes: paymentHistory,
            params: {
              api: `orders`,
              parentReference: "ticket",
              icon: "info",
              itemTitle: {
                name: "fullName",
                type: "text",
                collection: "authentication",
              },
              shortName: "Payment History",
              addPrivilege: false,
              delPrivilege: false,
              updatePrivilege: false,
              customClass: "medium",
              formMode: `double`,
            },
          },
        ],
      },
    },

    {
      element: "button",
      type: "subList",
      id: "IDCard",
      title: "ID Card",
      icon: "ticket",
      attributes: certificationData,
      params: {
        api: `certification-data`,
        parentReference: "ticket",
        icon: "certification",
        itemTitle: {
          name: "type",
          type: "text",
          collection: "",
        },
        shortName: "Certification Data",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
    {
      element: "button",
      type: "subTabs",
      id: "Config",
      title: "Configs",
      icon: "about-us",
      tabs: [
        {
          element: "button",
          type: "subList",
          id: "attendance",
          title: "Attendance",
          icon: "ticket-registration",
          attributes: attendance,
          params: {
            api: `attendance?scanType=event`,
            parentReference: "event",
            itemTitle: {
              name: "fullName",
              type: "text",
              collection: "user",
            },
            shortName: "Attendance",
            addPrivilege: false,
            delPrivilege: false,
            exportPrivilege: true,
            updatePrivilege: false,
            customClass: "medium",
          },
        },

        {
          element: "button",
          type: "custom",
          id: "eventForm",
          icon: "menu",
          title: "Event Form",
          content: eventForm,
        },
        {
          element: "button",
          type: "subList",
          id: "instance",
          title: "Instance",
          icon: "instance",
          attributes: instance,
          params: {
            api: `instance`,
            parentReference: "event",
            icon: "instance",
            itemTitle: { name: "title", type: "text", collection: "" },
            shortName: "Instance",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
            actions: [
              {
                element: "button",
                type: "subList",
                id: "attendance",
                title: "Attendance",
                attributes: attendance,
                params: {
                  api: `attendance`,
                  parentReference: "instance",
                  itemTitle: {
                    name: "fullName",
                    type: "text",
                    collection: "user",
                  },
                  shortName: "Attendance",
                  addPrivilege: false,
                  delPrivilege: false,
                  exportPrivilege: true,
                  updatePrivilege: false,
                  customClass: "medium",
                },
              },
            ],
          },
        },
        {
          element: "button",
          type: "subList",
          id: "user/eventAdmin",
          itemTitle: {
            name: "title",
            type: "text",
            collection: "",
          },
          exportPrivilege: true,
          title: "Event Admin",
          icon: "event-admin",
          attributes: eventAdmin,
          params: {
            api: `user/eventAdmin`,
            parentReference: "event",
            icon: "event-admin",
            itemTitle: {
              name: "title",
              type: "text",
              collection: "",
            },
            shortName: "Event Admin",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
            formMode: "double",
            exportPrivilege: true,
          },
        },
        {
          element: "button",
          type: "subList",
          id: "additionalMenus",
          title: "Menus & Routes",
          icon: "menu",
          attributes: additionalMenus,
          params: {
            api: `additional-menu`,
            parentReference: "event",
            itemTitle: {
              name: "title",
              type: "text",
              collection: "",
            },
            shortName: "Additional Menus",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
            formMode: "double",
          },
        },
        {
          element: "button",
          type: "subItem",
          id: "settings",
          title: "Settings",
          icon: "settings",
          attributes: settings,
          params: {
            api: `settings`,
            parentReference: "event",
            itemTitle: {
              name: "whatsappUrl",
              type: "text",
              collection: "",
            },
            shortName: "Settings",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
            formMode: "double",
          },
        },
        {
          element: "button",
          type: "custom",
          id: "landingPage",
          icon: "menu",
          title: "Landing Page",
          content: landingPage,
        },
        ...(checkprivilege([privileges.admin], userType)
          ? [
              {
                element: "button",
                type: "subList",
                id: "bulkMsg",
                title: "Bulk Whatsapp Message",
                icon: "bulkMsg",
                attributes: bulkMsg,
                params: {
                  api: `bulkMsg`,
                  parentReference: "event",
                  itemTitle: {
                    name: "whatsappUrl",
                    type: "text",
                    collection: "",
                  },
                  shortName: "Bulk Whatsapp Message",
                  addPrivilege: true,
                  delPrivilege: true,
                  updatePrivilege: true,
                  customClass: "medium",
                  formMode: "double",
                },
              },
            ]
          : []),
      ],
    },
    ...(checkprivilege([privileges.admin], userType)
      ? [
          {
            element: "button",
            type: "subList",
            id: "whitelisted-Domains",
            title: "Whitelisted Domain",
            icon: "whitelisted-Domains",
            attributes: whitelistedDomain,
            params: {
              api: `whitelisted-Domains`,
              parentReference: "event",
              icon: "whitelisted-Domains",
              itemTitle: {
                name: "domain",
                type: "text",
                collection: "",
              },
              shortName: "Whitelisted Domain",
              addPrivilege: true,
              delPrivilege: true,
              updatePrivilege: true,
              customClass: "medium",
              formMode: "double",
            },
          },
        ]
      : []),

    {
      element: "button",
      type: "subTabs",
      id: "websiteContent",
      title: "Website Content",
      icon: "about-us",
      tabs: [
        {
          element: "button",
          type: "subList",
          id: "collection",
          title: "Collection",
          icon: "about-us",
          attributes: collection,
          params: {
            api: `collection`,
            parentReference: "event",
            itemTitle: {
              name: "page",
              type: "text",
              collection: "",
            },
            shortName: "Collection",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
            formMode: "double",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "count",
          title: "Count",
          icon: "count",
          attributes: count,
          params: {
            api: `count`,
            parentReference: "event",
            itemTitle: {
              name: "title",
              type: "text",
              collection: "",
            },
            shortName: "Count",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "gallery",
          title: "Gallery",
          icon: "gallery",
          attributes: gallery,
          params: {
            api: `gallery`,
            parentReference: "event",
            itemTitle: {
              name: "title",
              type: "text",
              collection: "",
            },
            shortName: "Gallery",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "news",
          title: "News",
          icon: "news",
          attributes: news,
          params: {
            api: `news`,
            parentReference: "event",
            itemTitle: {
              name: "title",
              type: "text",
              collection: "",
            },
            shortName: "News",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "speakers",
          title: "Speakers",
          icon: "speakers",
          attributes: speakers,
          params: {
            api: `speakers`,
            parentReference: "event",
            itemTitle: {
              name: "name",
              type: "text",
              collection: "",
            },
            shortName: "Speakers",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "sponsors",
          title: "Sponsors",
          icon: "sponsors",
          attributes: sponsors,
          params: {
            api: `sponsors`,
            parentReference: "event",
            itemTitle: {
              name: "title",
              type: "text",
              collection: "",
            },
            shortName: "Sponsors",
            profileImage: "logo",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "features",
          title: "Features",
          icon: "features",
          attributes: features,
          params: {
            api: `features`,
            parentReference: "event",
            itemTitle: {
              name: "type",
              type: "text",
              collection: "",
            },
            shortName: "Features",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "testimonial",
          title: "Testimonial",
          icon: "testimonial",
          attributes: testimonial,
          params: {
            api: `testimonial`,
            parentReference: "event",
            itemTitle: {
              name: "title",
              type: "text",
              collection: "",
            },
            shortName: "Testimonial",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "faq",
          title: "Faq",
          icon: "faq",
          attributes: faq,
          params: {
            api: `faq`,
            parentReference: "event",
            itemTitle: {
              name: "question",
              type: "text",
              collection: "",
            },
            shortName: "Faq",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            customClass: "medium",
          },
        },
        {
          element: "button",
          type: "subList",
          id: "luckyDraw",
          title: "Lucky Draw",
          icon: "ticket",
          attributes: luckyDraw,
          params: {
            api: `lucky-draw`,
            parentReference: "event",
            itemTitle: {
              name: "firstName",
              type: "text",
              collection: "",
            },
            shortName: "Lucky Draw",
            addPrivilege: true,
            delPrivilege: true,
            updatePrivilege: true,
            exportPrivilege: true,
            customClass: "medium",
            formMode: "double",
          },
        },
      ],
    },
    {
      element: "button",
      type: "subList",
      id: "landingPageConfig",
      title: "Page Sections",
      icon: "landingPageConfig",
      attributes: landingPageConfig,
      params: {
        api: `landingPageConfig`,
        parentReference: "event",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "",
        },
        shortName: "Page Sections",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: "double",
      },
    },
  ]);

  const getApproved = (userId, refreshView) => {
    props.setLoaderBox(true);
    getData({ userId }, "ticket-registration/send-id")
      .then((response) => {
        props.setLoaderBox(false);
        console.log(response);
        if (response.data) {
          props.setMessage({ content: response.data.message });
          refreshView();
        } else {
          // Handle the case where response.data is undefined
          console.error("Response data is undefined.");
        }
      })
      .catch((error) => {
        props.setLoaderBox(false);
        // Handle any errors that occur during the API request
        console.error("API request error:", error);
      });
  };

  return (
    <Container className="noshadow">
      <ListTable profileImage={"logo"} bulkUplaod={false} isSingle={false} popupMode="full-page" popupMenu={"vertical-menu"} parentReference={"event"} actions={actions} api={checkprivilege([privileges.admin], userType) ? "event" : `event/event-admin`} itemTitle={{ name: "title", type: "text", collection: "" }} shortName={`Event`} formMode={`double`} {...props} attributes={attributes}></ListTable>
    </Container>
  );
};
// exporting the page with parent container layout..
export default Layout(Event);
