import React from "react";
import { SubHead } from "../styles";
import { GetIcon } from "../../../../icons";

const CustomTitle = ({ icon, title, description = "", dynamicClass = "", line = true }) => {
  return (
    <SubHead className={`title ${dynamicClass} ${line ? "line" : ""} ${line ? "margin" : ""} `}>
      {title?.length > 0 && (
        <div>
          {icon ? <GetIcon icon={icon}></GetIcon> : null}
          {title}
        </div>
      )}
      {description.length > 0 && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
    </SubHead>
  );
};

export default CustomTitle;
