import React, { useEffect, useState } from "react";
import Layout from "../../../../core/layout";
import { GetIcon } from "../../../../../icons";
import { Content, DragBox, SideBar } from "./ticketForm.styled";
import Elements from "./elements";

import { ElementContainer, TabButtons } from "../../../../core/elements";
import { getData } from "../../../../../backend/api";
import AutoForm from "../../../../core/autoform/AutoForm";

const EventForm = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState([]);

  const handleBackClick = () => {
    setActiveTab(0);
    console.log("back clicked");
  };

  useEffect(() => {
    getData({ ticket: props?.openData?.data?._id }, "ticket-form-data").then(
      (response) => {
        setFormData(response?.data?.response);
      }
    );
  }, [props]);

  console.log(formData, "loggingg response");

  const tabs = [
    {
      key: 0,
      title: "Elements",
      icon: "user",
    },
    {
      key: 1,
      title: "Content",
      icon: "user",
    },
  ];

  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;
  // const port = window.location.port;
  return (
    <div
      style={{
        flexWrap: "wrap",
        display: "flex",
        height: "calc(100vh - 100px)",
      }}
    >
      <SideBar>
        <ElementContainer>
          <TabButtons
            selectedTab={activeTab}
            selectedChange={(value) => {
              setActiveTab(value);
            }}
            tabs={tabs}
          ></TabButtons>
        </ElementContainer>
        <ElementContainer
          className="column"
          style={{ display: 0 === activeTab ? "block" : "none" }}
        >
          <Elements
            onBackClick={handleBackClick}
            props
            event={props?.openData?.data?._id}
          />
        </ElementContainer>
        <ElementContainer
          style={{ display: 1 === activeTab ? "block" : "none" }}
          className="noshadow top"
        >
          {/* <ListTable
                preFilter={{ event: `${props?.openData?.data?._id}` }}
                // actions={actions}
                api={form.api}
                itemTitle={{
                  name: form.itemTitle,
                  type: "text",
                  collection: "",
                }}
                shortName={form.shortName}
                formMode={`double`}
                attributes={form.attributes}
                {...props}
              /> */}
          <></>
        </ElementContainer>
      </SideBar>
      <Content>
        {/* {data && config ? (
          <iframe
            referrerPolicy="no-referrer"
            sandbox="allow-same-origin allow-scripts allow-storage-access-by-user-activation"
            title="Demo Event"
            src={`${protocol}//${hostname}${
              port ? `:${port}` : ""
            }/landing-page/${props?.openData?.data?._id}`}
          ></iframe>
        ) : (
          // <Event config={config} data={data} theme={selectedTheme?.key} desktopScrolling={desktopScrolling}></Event>
          <Loader />
        )} */}
        {formData.length === 0 ? (
          <DragBox>
            <GetIcon icon="circlePlus" />
            Drag Elements Here
          </DragBox>
        ) : (
          <ElementContainer className="dashitem">
            <AutoForm
              useCaptcha={false}
              key={"elements"}
              formType={"post"}
              header={"Yes"}
              description={""}
              customClass={"embed"}
              css="plain embed head-hide"
              // header={"IPH REPORT 2023-24"}
              // description={
              //   "2023 ഏപ്രിൽ മുതൽ 2024 മാറ്ച്ച് 31 വരെയുള്ള റിപ്പോറ്ട്ട് നൽകുക"
              // }
              formInput={formData}
              // submitHandler={submitChange}
              // button={"Save"}
              // isOpenHandler={isCreatingHandler}
              isOpen={true}
              plainForm={true}
              formMode={"single"}
            ></AutoForm>
          </ElementContainer>
        )}
      </Content>
    </div>
  );
};
export default Layout(EventForm);
