import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
import PopupView from "../../../core/popupview";
import { useSelector } from "react-redux";
import CustomForm from "../customForm";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const Ticket = (props) => {
  const themeColors = useSelector((state) => state.themeColors);
  const [openMenuSetup, setOpenMenuSetup] = useState(false);
  const [openedMenu] = useState("");
  console.log("openMenuSetup", openMenuSetup);
  console.log("openedMenu", openedMenu);
  // State to store the data for the item that was clicked on in the ListTable
  const [openItemData, setOpenItemData] = useState(null);
  console.log("openItemData", openItemData);
  const closeModal = () => {
    setOpenMenuSetup(false);
    setOpenItemData(null);
  };

  //to update the page title
  useEffect(() => {
    document.title = `Ticket - EventHex Portal`;
  }, []);

  const [attributes] = useState([
    {
      type: "text",
      placeholder: "Title",
      name: "title",
      validation: "",
      default: "",
      label: "Title",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "event/select",
      placeholder: "Event",
      name: "event",
      validation: "",
      showItem: "title",
      tag: true,
      default: "",
      label: "Event",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: false,
    },
    {
      type: "checkbox",
      placeholder: "Status",
      name: "status",
      validation: "",
      default: null,
      tag: true,
      label: "Status",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Start Date",
      name: "startDate",
      validation: "",
      default: "",
      tag: true,
      label: "Start Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "End Date",
      name: "endDate",
      validation: "",
      default: "",
      tag: true,
      label: "End Date",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Group Ticketing",
      name: "groupTicketing",
      validation: "",
      default: null,
      tag: true,
      label: "Group Ticketing",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Paid",
      name: "freeOrPaid",
      validation: "",
      default: "",
      tag: true,
      label: "Paid",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Cost",
      name: "cost",
      condition: {
        item: "freeOrPaid",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Cost",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Tax",
      name: "tax",
      condition: {
        item: "freeOrPaid",
        if: true,
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Tax",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "image",
      placeholder: "Banner",
      name: "banner",
      validation: "",
      default: "",
      tag: true,
      label: "Banner",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [ticketFormData] = useState([
    {
      type: "text",
      placeholder: "Name",
      name: "name",
      validation: "",
      default: "",
      label: "Name",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Place Holder",
      name: "placeHolder",
      validation: "",
      default: "",
      label: "Place Holder",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Label",
      name: "label",
      validation: "",
      default: "",
      label: "Label",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Order Id",
      name: "orderId",
      validation: "",
      default: "",
      label: "Order Id",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Type",
      name: "type",
      validation: "",
      default: "",
      tag: true,
      label: "Type",
      showItem: "Type",
      required: true,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi:
        "select,multiSelect,text,textarea,image,checkbox,date,datetime,number,email,password",
    },
    {
      type: "select",
      placeholder: "Api Type",
      name: "apiType",
      condition: {
        item: "type",
        if: "select",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Api Type",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "CSV,json",
    },
    {
      type: "select",
      placeholder: "Api Type",
      name: "apiType",
      condition: {
        item: "type",
        if: "multiSelect",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Api Type",
      required: true,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "CSV,json",
    },
    {
      type: "textarea",
      placeholder: "Select Api",
      name: "selectApi",
      condition: {
        item: "type",
        if: "select",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Select Api",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Select Api",
      name: "selectApi",
      condition: {
        item: "type",
        if: "multiSelect",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Select Api",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Validation",
      name: "validation",
      validation: "",
      default: "",
      label: "Validation",
      tag: true,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Show Item",
      name: "showItem",
      validation: "",
      default: "",
      label: "Show Item",
      tag: true,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Default",
      name: "default",
      validation: "",
      default: "",
      label: "Default",
      tag: true,
      // required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "title",
      title: "Permission Settings",
      name: "sm",
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Tag",
      name: "tag",
      validation: "",
      default: null,
      tag: true,
      label: "Tag",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Required",
      name: "required",
      validation: "",
      default: null,
      tag: true,
      label: "Required",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "View",
      name: "view",
      validation: "",
      default: null,
      tag: true,
      label: "View",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Add",
      name: "add",
      validation: "",
      default: null,
      tag: true,
      label: "Add",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Update",
      name: "update",
      validation: "",
      default: null,
      tag: true,
      label: "Update",
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Filter",
      name: "filter",
      validation: "",
      default: null,
      tag: true,
      label: "Filter",
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [ticketRegistration] = useState([
    {
      type: "select",
      apiType: "API",
      selectApi: "event/select",
      placeholder: "Event",
      name: "event",
      validation: "",
      showItem: "title",
      // tag: true,
      default: "",
      label: "Event",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Form Data",
      name: "formData",
      validation: "",
      default: "",
      label: "Form Data",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "date",
      placeholder: "Registration",
      name: "registration",
      validation: "",
      default: "",
      label: "Registration",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "time",
      placeholder: "Time",
      name: "time",
      validation: "",
      default: "",
      label: "Time",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "IP",
      name: "ip",
      validation: "",
      default: "",
      label: "IP",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [certificationData] = useState([
    {
      type: "select",
      placeholder: "Type",
      name: "type",
      validation: "",
      default: "",
      label: "Type",
      showItem: "Type",
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "badge,certificate",
    },
    {
      type: "select",
      placeholder: "Layer Type",
      name: "layerType",
      validation: "",
      default: "",
      label: "Layer Type",
      showItem: "Layer Type",
      tag: true,
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "BackgroundImage,BackgroundGradient,Text,Image,Qr",
    },
    {
      type: "select",
      placeholder: "Source",
      name: "source",
      condition: {
        item: "layerType",
        if: "BackgroundImage",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Source",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "BackgroundImageUrl",
    },
    {
      type: "select",
      placeholder: "Position",
      name: "position",
      condition: {
        item: "layerType",
        if: "BackgroundImage",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Position",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "cover,contain",
    },
    {
      type: "select",
      placeholder: "Source",
      name: "source",
      condition: {
        item: "layerType",
        if: "BackgroundGradient",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Source",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "ColourPicker",
    },
    {
      type: "select",
      placeholder: "Source",
      name: "source",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Source",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "FieldName",
    },
    {
      type: "text",
      placeholder: "Top",
      name: "top",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Top",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Bottom",
      name: "bottom",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Bottom",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Left",
      name: "left",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "auto",
      tag: true,
      label: "Left",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Right",
      name: "right",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Right",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Alignment",
      name: "textAlignment",
      condition: {
        item: "layerType",
        if: "Text",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Alignment",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Left,Right,Justify,Center",
    },
    {
      type: "select",
      placeholder: "Alignment",
      name: "textAlignment",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Alignment",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Left,Right,Justify,Center",
    },
    {
      type: "text",
      placeholder: "Top",
      name: "top",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Top",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Bottom",
      name: "bottom",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Bottom",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Left",
      name: "left",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "auto",
      tag: true,
      label: "Left",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Right",
      name: "right",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Right",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Position",
      name: "position",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Position",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "cover,contain",
    },
    {
      type: "select",
      placeholder: "Shape",
      name: "shape",
      condition: {
        item: "layerType",
        if: "Image",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Shape",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "round,square,curved",
    },
    {
      type: "select",
      placeholder: "Alignment",
      name: "textAlignment",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Alignment",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Left,Right,Justify,Center",
    },
    {
      type: "text",
      placeholder: "Top",
      name: "top",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Top",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Bottom",
      name: "bottom",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Bottom",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Left",
      name: "left",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "auto",
      tag: true,
      label: "Left",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Right",
      name: "right",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "10px",
      tag: true,
      label: "Right",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Source",
      name: "source",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Source",
      required: false,
      view: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "FieldName",
    },
    {
      type: "text",
      placeholder: "Prefix",
      name: "prefix",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Prefix",
      required: false,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "text",
      placeholder: "Suffix",
      name: "suffix",
      condition: {
        item: "layerType",
        if: "Qr",
        then: "enabled",
        else: "disabled",
      },
      showItem: "",
      validation: "",
      default: "",
      tag: true,
      label: "Suffix",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);

  const [ticketCoupen] = useState([
    {
      type: "text",
      placeholder: "Coupon Code",
      name: "code",
      validation: "",
      default: "",
      label: "Coupon Code",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Discount Type",
      name: "discountType",
      validation: "",
      default: "",
      label: "Discount Type",
      showItem: "Discount Type",
      required: false,
      view: true,
      filter: false,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "percentage,fixed",
    },
    {
      type: "number",
      placeholder: "Discount  Value",
      name: "discountValue",
      validation: "",
      default: "",
      label: "Discount  Value",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "datetime",
      placeholder: "Valid From",
      name: "validFrom",
      validation: "",
      default: "",
      label: "Valid From",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "datetime",
      placeholder: "Valid To",
      name: "validUntil",
      validation: "",
      default: "",
      label: "Valid To",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },

    {
      type: "number",
      placeholder: "Max Uses",
      name: "maxUses",
      validation: "",
      default: "",
      label: "Max Uses",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "checkbox",
      placeholder: "Status",
      name: "isActive",
      validation: "",
      default: null,
      tag: true,
      label: "Status",
      required: false,
      view: true,
      add: true,
      update: true,
    },
  ]);
  const [actions] = useState([
    {
      element: "button",
      type: "subList",
      id: "ticket-form-data",
      itemTitle: "orderId",
      title: "Ticket Form Data",
      attributes: ticketFormData,
      params: {
        api: `ticket-form-data`,
        parentReference: "ticket",
        icon: "ticket-form-data",
        itemTitle: {
          name: "orderId",
          type: "text",
          collection: "",
        },
        shortName: "Ticket Form Data",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "ticket-registration",
      itemTitle: "title",
      title: "Ticket Registration",
      attributes: ticketRegistration,
      params: {
        api: `ticket-registration`,
        parentReference: "ticket",
        icon: "ticket-registration",
        itemTitle: {
          name: "title",
          type: "text",
          collection: "event",
        },
        shortName: "Ticket Registration",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "certification-data",
      itemTitle: "title",
      title: "Certification Data",
      attributes: certificationData,
      params: {
        api: `certification-data`,
        parentReference: "ticket",
        icon: "certification",
        itemTitle: {
          name: "type",
          type: "text",
          collection: "",
        },
        shortName: "Certification Data",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
    {
      element: "button",
      type: "subList",
      id: "coupen",
      itemTitle: "title",
      title: "Coupen",
      attributes: ticketCoupen,
      params: {
        api: `coupen`,
        parentReference: "ticket",
        icon: "coupen",
        itemTitle: {
          name: "type",
          type: "text",
          collection: "",
        },
        shortName: "coupen",
        addPrivilege: true,
        delPrivilege: true,
        updatePrivilege: true,
        customClass: "medium",
        formMode: `double`,
      },
    },
  ]);
  console.log("actions", actions);
  return (
    <Container className="noshadow">
      <ListTable
        actions={actions}
        api={`ticket`}
        // itemTitle={`label`}
        itemTitle={{
          name: "title",
          type: "text",
          collection: "",
        }}
        shortName={`Ticket`}
        formMode={`double`}
        attributes={attributes}
        {...props}
      ></ListTable>
      {openedMenu === "configure" && openMenuSetup && openItemData && (
        <PopupView
          customClass={"large"}
          popupData={<CustomForm></CustomForm>}
          themeColors={themeColors}
          closeModal={closeModal}
          itemTitle={{
            name: "title",
            type: "text",
            collection: "",
          }}
          openData={{ data: { _id: "", title: "Configure Form" } }} // Pass selected item data to the popup for setting the time and taking menu id and other required data from the list item
        ></PopupView>
      )}
    </Container>
  );
};

export default Layout(Ticket);
