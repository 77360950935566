import { useEffect, useState } from "react";
import { GetIcon } from "../../../../icons";
import { ElementContainer, Select } from "../../elements";
import {
  Count,
  IconWrapper,
  Summary,
  Tile,
  TileContainer,
  TitleBox,
  TitleHead,
  Titles,
} from "./styles";
import { Title } from "../styles";
import { getData } from "../../../../backend/api";

const HighlightCards = ({
  title = "No Title Found",
  description = "",
  dataType = "API",
  filterType = "JSON",
  filters = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
  ],
  dataItem = "dashboard",
}) => {
  const [filterItems, setFilterItems] = useState(filters);
  const [mainData, setMainData] = useState(dataItem);
  const [filter, setFilter] = useState("");
  useEffect(() => {
    if (dataType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData(filter ? { filter } : {}, api);
          setMainData(response.data);
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      };
      fetchData(dataItem);
    }
  }, [dataItem, dataType, filter]);

  useEffect(() => {
    if (filterType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData({}, api);
          setFilterItems(response.data);
        } catch (error) {
          console.error("Error fetching filter items from API:", error);
        }
      };
      fetchData(filters);
    }
  }, [filters, filterType]);
  return (
    <ElementContainer className="dashitem column">
      <Title>
        <Titles>
          <span>{title}</span>
          <Summary>{description}</Summary>
        </Titles>
        <Select
          label="Month"
          align="right small"
          value={filter}
          selectApi={filterItems}
          onSelect={(item) => {
            console.log("Selected Value", filter, item);
            setFilter(item.id ?? null);
          }}
        ></Select>
      </Title>

      <TileContainer>
        {Array.isArray(mainData) &&
          mainData?.map((item, index) => {
            return index < 4 ? (
              <Tile
                style={{ background: item.background, color: item.color }}
                key={index}
              >
                <TitleBox>
                  <IconWrapper
                    style={{ background: "white", color: item.color }}
                  >
                    <GetIcon icon={item.icon} />
                  </IconWrapper>
                  <Count>{item.count}</Count>
                </TitleBox>
                <TitleHead>{item.title}</TitleHead>
                <TitleHead style={{ color: item.color }} className="info">
                  {item.info ?? "information"}
                </TitleHead>
              </Tile>
            ) : null;
          })}
      </TileContainer>
    </ElementContainer>
  );
};

export default HighlightCards;
