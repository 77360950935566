import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GetIcon } from "../../../../../../icons";
import { postData } from "../../../../../../backend/api";
import AutoForm from "../../../../../core/autoform/AutoForm";
const PageWrapper = styled.div`
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  background: white;
  left: 0;
  bottom: 0;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 6px), repeating-linear-gradient(#b4b4b455, #b4b4b4);
  &.Horizontal {
    display: flex;
    @media (max-width: 768px) {
      height: auto;
      overflow: auto;
    }
  }
  &.Vertical {
    background: #00000075;
  }
  ~ body {
    overflow: hidden;
  }
`;

const Banner = styled.div`
  position: fixed;
  left: auto;
  bottom: 0;
  top: 0;
  right: right;
  width: auto;
  overflow: auto;

  img {
    /* align-items: center;
    object-fit: cover;
    object-position: top; */
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: auto;
    margin-left: auto;
  }
  &.Vertical img {
    display: none;
  }
  div {
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0)) center center / cover no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  &.Horizontal {
    width: 40%;
    right: 0px;
    left: auto;
    height: 100vh;
    position: inherit;
    div {
      background: transparent;
    }
    img {
      height: 100%;
      border-radius: 0;
    }
  }
  &.single {
    width: calc(100% - 500px);
  }
  &.Vertical {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 768px) {
    img {
      position: inherit;
    }
    &.Horizontal {
      width: 100%;
    }
  }
`;
const Content = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: auto;
  width: 60%;
  overflow: auto;
  padding: 30px;
  min-width: 850px;
  max-width: 60%;
  z-index: 1;
  &.Horizontal {
    width: 60%;
    position: initial;
    /* height: 100vh; */
    /* overflow: auto; */
    display: flex;
  }
  &.single {
    width: 500px;
    min-width: 500px;
  }
  &.Vertical {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: 768px) {
    &.Horizontal {
      width: 100%;
      height: auto;
      overflow: hidden;
    }
  }
`;
const FormHeader = styled.div`
  text-align: left;
  padding: 10px 0 0 0;
  span > bold {
    font-weight: 700;
  }
  span > span {
    font-size: 17px;
  }
  > span {
    font-size: 1.5em;
    text-align: left;
    -webkit-box-align: baseline;
    align-items: baseline;
    font-weight: normal;
    flex-direction: column;
    color: black;
  }
  i {
    border: 1px dashed;
    padding: 0px 5px;
    margin-left: 5px;
    font-style: normal;
    cursor: pointer;
  }
  svg {
    margin: 0;
    font-size: 2px;
    border-radius: 50%;
    width: 13px;
    height: 13px;
  }
`;
const Header = styled.div`
  padding: 0px 20px 0px;
  background: ${(props) => props.event?.themeColor};
  color: ${(props) => props.event?.themeTextColor};
  align-items: center;
  display: flex;
  color: white;
  border-radius: 11px 11px 0px 0px;
  height: 50px;
  font-size: 16px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0;
    font-size: 18px;
    color: white;
  }
  div {
    cursor: pointer;
    align-items: center;
    display: flex;
    gap: 5px;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    padding: 10px 20px;
    border-radius: 0px;
    h2 {
      font-size: 15px;
    }
  }
`;
const CenteredDiv = styled.div`
  width: fit-content;
  max-width: 800px; /* Adjust as needed */
  padding: 0px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: auto;
  border-radius: 12px;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    border-radius: 0;
  }
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 40%;
  margin-bottom: 60px;
  margin-top: 60px;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: auto;
  position: relative;
  padding-bottom: 10px;
  color: black;
  img {
    max-width: 100%;
  }
  &.embed {
    margin: 0px auto;
    padding: 10px 25px 25px;
    width: 400px;
    &.Horizontal {
      align-items: flex-start;
      justify-content: flex-start;
      /* overflow: auto; */
    }
    &.double {
      width: 650px;
    }
  }
  @media screen and (max-width: 1200px) and (min-width: 768px) {
    max-width: 768px;
  }
  @media screen and (max-width: 768px) {
    flex: 1 1 100%;
    width: auto;
    padding: 10px;
    margin: 0px auto;
    &.embed {
      margin: 0px auto;
      padding: 10px 25px 25px;
      width: 100%;
      &.Horizontal {
        /* max-height: inherit;
        min-height: auto !important;
        overflow: auto;
        display: flex;
        justify-content: flex-start; */
        /* max-height: 90vh; */
      }
      &.double {
        max-width: 100%;
      }
    }
  }
`;
const ErrorMessage = styled.div`
  margin: 5px 10px 0px;
  color: rgb(254, 123, 123);
  font-size: 12px;
  width: 100%;
  border-top: 1px solid lightgrey;
  text-align: center;
  padding: 10px;
`;
const Message = styled.div`
  margin: 10px 0 0 0;
  font-size: 16px;
  width: 100%;
  text-align: left;
  padding: 0;
`;
const DirectRegister = ({ setLoaderBox, event, registserHandler, ticket, single = false }) => {
  const [userRegistered, setUserRegistered] = useState(false);
  const [input, setInput] = useState(null);
  const [formId, setFormId] = useState(0);
  const [couenFormId, setCouenFormId] = useState(0);
  const [couponApplied, setCouponApplied] = useState(null);
  const [message, setMessage] = useState("");
  const [couponForm, setCouponForm] = useState(false);
  const [formMode] = useState(ticket.formMode ?? (input?.length > 6 ? "double" : "single"));

  useEffect(() => {
    // Set overflow hidden on body when component mounts
    document.body.style.overflow = "hidden";

    // Cleanup function to reset overflow when component unmounts
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    const getTicket = async () => {
      const country = event.countries[0];
      const response = await postData({ direct: true, ticket: ticket._id }, "authentication/ticket-direct-data");
      const regForm = [
        {
          type: "text",
          placeholder: ticket.overrideYourNameField ?? "Type Your Name",
          name: "firstName",
          format: ticket.overrideYourNameValidation ?? "propercase",
          validation: "name",
          default: "",
          label: ticket.overrideYourNameField ?? "Type Your Name",
          minimum: 3,
          info: ticket.overrideYourNameInfo ?? "",
          maximum: 40,
          required: true,
          add: true,
        },
        {
          type: "hidden",
          placeholder: "Last Name",
          name: "lastName",
          validation: "name",
          default: "",
          label: "Last Name",
          minimum: 1,
          maximum: 40,
          required: false,
          add: true,
        },
        {
          type: "mobilenumber",
          placeholder: `WhatsApp Number`,
          name: "authenticationId",
          validation: "mobileNumber",
          info: "This number will be your unique indentification number",
          default: "",
          label: `WhatsApp Number`,
          minimum: 0,
          countries: event.countries,
          required: true,
          add: true,
        },
        {
          type: "email",
          placeholder: "Email ID",
          name: "emailId",
          customClass: "full",
          validation: "email",
          info: "We will use this Email-ID for further email communication!",
          default: "",
          label: "Email ID",
          minimum: 0,
          maximum: 50,
          required: event.collectEmailId ? true : false,
          add: true,
        },
        {
          type: "hidden",
          placeholder: "PhoneNumberLength",
          name: "PhoneNumberLength",
          validation: "",
          default: country.PhoneNumberLength,
          label: "PhoneNumberLength",
          minimum: 1,
          maximum: 40,
          required: false,
          add: true,
        },
        {
          type: "hidden",
          placeholder: "phoneCode",
          name: "phoneCode",
          default: country.phoneCode,
          validation: "",
          label: "phoneCode",
          minimum: 1,
          maximum: 40,
          required: false,
          add: true,
        },
        ...response.data.response.map((attribute) => {
          if (attribute.conditionEnabled) {
            attribute.condition = {
              item: attribute.conditionWhenField,
              if: attribute.conditionCheckMatch.includes(",") ? attribute.conditionCheckMatch.split(",") : [attribute.conditionCheckMatch],
              then: attribute.conditionIfMatch === "enabled" ? "enabled" : "disabled",
              else: attribute.conditionIfMatch === "enabled" ? "disabled" : "enabled",
            };
          }
          if (attribute.type === "select") {
            attribute.search = true;
          }
          if (attribute.type === "multiSelect") {
            if (attribute.apiType === "CSV") {
              attribute.selectApi = attribute.selectApi
                .toString()
                .split(",")
                .map((item) => {
                  return { id: item, value: item };
                });
              attribute.apiType = "JSON";
            }
            attribute.default = "";
          }
          return attribute;
        }),
      ];
      setInput(regForm);
      setFormId((formId) => formId + 1);
    };
    if (!input || input?.length === 0) {
      getTicket();
    }
  }, [event.loginPage, input, event.countries, ticket, event.collectEmailId]);

  const isCreatingHandler = (value, callback) => {};
  const verfiyCoupen = async (temppost) => {
    setLoaderBox(true);
    const postDataTemp = { ticketId: ticket._id, ...temppost };
    await postData(postDataTemp, "authentication/verify-coupen").then((res) => {
      if (res.status === 200) {
        setCouponApplied({ ...res.data, couponCode: temppost.couponCode });
        setMessage("");
      } else {
        setMessage(res.customMessage);
        setCouenFormId((prev) => prev + 1);
      }
      setLoaderBox(false);
    });
  };
  const submitChange = async (temppost) => {
    setLoaderBox(true);
    const postDataTemp = { authenticationType: event.authenticationType, event: event._id, ticket: ticket._id, franchise: event.franchise._id, domain: window.location.hostname, ...(couponApplied?.couponId ? { couponId: couponApplied?.couponId } : {}), ...temppost };
    await postData(postDataTemp, "authentication/direct").then((res) => {
      if (res.status === 200) {
        if (res.data.status === "payment") {
          console.log(res.response);
          const orderData = res.data.order;
          if (orderData.razorpayOrderId) {
            const options = {
              key: orderData.key,
              amount: orderData.amount,
              currency: orderData.currency,
              name: orderData.company.title,
              description: "Event Registration",
              order_id: orderData.razorpayOrderId,
              image: process.env.REACT_APP_CDN + orderData.company.logo, // Add this line for the logo
              handler: async function (response) {
                setLoaderBox(true);
                const res = await postData({ ...postDataTemp, ...response, domain: window.location.hostname, reference: orderData.reference }, "authentication/direct");
                if (res.status === 200) {
                  setUserRegistered(true);
                  setMessage(res.data.response.onsuccessfullMessage);
                } else if (res.status === 400) {
                  setMessage(res.customMessage);
                }
                setLoaderBox(false);
              },
              prefill: {
                name: postDataTemp.firstName,
                email: postDataTemp.emailId,
                contact: postDataTemp.authenticationId,
              },
              notes: {
                address: "",
              },
              theme: {
                color: orderData.company.color,
              },
            };

            const rzp = new window.Razorpay(options);
            rzp.on("payment.failed", function (response) {
              setMessage("If your payment was successful but you haven't received confirmation, don't worry! Our team will process it within one hour. If you have concerns, contact our support team after one hour.");
              console.error("Payment failed:", response.error);
            });
            rzp.open();
          }
        } else if (res.data.status === "success") {
          setUserRegistered(true);
          setMessage(res.data.response.onsuccessfullMessage);
        } else if (res.data.status === "false") {
          //crreat payment opip razorpay
        }
      } else if (res.status === 400) {
        setMessage(res.customMessage);
      }
      setLoaderBox(false);
    });
  };
  return (
    <PageWrapper className={`${event.loginPage}`} event={event}>
      <Banner className={`${event.loginPage}  ${formMode}`}>
        <img src={process.env.REACT_APP_CDN + (ticket.banner ?? event.regBanner)} alt="frame" />
        <div></div>
      </Banner>
      <Content className={`${event.loginPage} ${formMode}`}>
        <CenteredDiv>
          {single ? (
            <Header event={event}>
              <h2>{input ? "Registration" : "Loading.."}</h2>{" "}
            </Header>
          ) : (
            <Header event={event}>
              <h2>{input ? "Registration" : "Loading!"}</h2>
              <div onClick={() => registserHandler()}>
                <GetIcon icon={"close "}></GetIcon>
              </div>
            </Header>
          )}

          {userRegistered ? (
            <FormContainer className={`${event.loginPage}  embed ${formMode}`}>
              <Message dangerouslySetInnerHTML={{ __html: message }}></Message>
            </FormContainer>
          ) : (
            input && (
              <FormContainer className={`${event.loginPage}  embed  ${formMode}`}>
                {!userRegistered && input?.length > 0 && (
                  <React.Fragment>
                    <FormHeader>
                      <span>
                        <strong>{ticket.title}</strong>
                        {single ? null : (
                          <>
                            , <br />
                            <span>Registration Form</span>
                          </>
                        )}

                        {ticket.enablePayment &&
                          (couponApplied ? (
                            <p style={{ fontSize: "14px", fontStyle: "italic" }}>
                              Congratulations! The coupon discount of {couponApplied.discountValue}% has been applied. You need to pay <strong style={{ fontWeight: "bold" }}>{couponApplied.discountAmount}</strong> ({ticket.currency}) to complete this registration! -{" "}
                              <i>
                                {couponApplied.couponCode}
                                <span onClick={() => setCouponApplied(null)}>
                                  <GetIcon icon={"delete"} />
                                </span>
                              </i>
                            </p>
                          ) : (
                            <p style={{ fontSize: "14px", fontStyle: "italic", lineHeight: "20px" }}>
                              You need to pay <strong style={{ fontWeight: "bold" }}>{ticket.paymentAmount}</strong> ({ticket.currency}) to complete this registration!
                              {ticket?.enableCoupenCode && (
                                <>
                                  <br />
                                  Do you have a coupon code?
                                  <i
                                    onClick={() => {
                                      setMessage("");
                                      setCouponForm(true);
                                    }}
                                  >
                                    Apply Here
                                  </i>
                                </>
                              )}
                            </p>
                          ))}
                      </span>
                    </FormHeader>
                    <AutoForm useCaptcha={false} key={`type-${formId}`} useCheckbox={false} customClass="embed" description="" formValues={{}} formMode={formMode} formType="post" header=" " formInput={input} submitHandler={submitChange} button={ticket.enablePayment ? "Pay & Register" : "Register Now"} isOpenHandler={isCreatingHandler} css="plain embed head-hide landing" isOpen={true} plainForm={true} />
                  </React.Fragment>
                )}
                {!userRegistered && message?.length > 0 && <ErrorMessage>{message}</ErrorMessage>}
                {userRegistered && message?.length > 0 && <Message>{message}</Message>}
                {ticket && couponForm && ticket?.enableCoupenCode && !couponApplied && (
                  <Message>
                    <AutoForm
                      useCaptcha={false}
                      key={`coupen-${couenFormId}`}
                      useCheckbox={false}
                      customClass={"embed"}
                      description={""}
                      formValues={{}}
                      formMode={"single"}
                      formType={"post"}
                      header={`<span style="font-size:14px;">Do you have a Coupon Code?</span>`}
                      formInput={[
                        {
                          type: "text",
                          placeholder: "Coupon Code",
                          name: "couponCode",
                          format: "uppercase",
                          default: "",
                          label: "Coupon Code",
                          minimum: 3,
                          info: "Enter your you Coupon code recived from the team!",
                          maximum: 15,
                          required: true,
                          add: true,
                        },
                        ...(message.length > 0
                          ? [
                              {
                                type: "html",
                                content: <ErrorMessage>{message}</ErrorMessage>,
                                name: "eror",
                                customClass: "full",
                                required: false,
                                add: true,
                              },
                            ]
                          : []),
                      ]}
                      submitHandler={verfiyCoupen}
                      button={"Apply"}
                      isOpenHandler={() => {
                        setCouponForm(false);
                        setMessage("");
                      }}
                      css="landing"
                      isOpen={true}
                      plainForm={false}
                    ></AutoForm>
                  </Message>
                )}
              </FormContainer>
            )
          )}
        </CenteredDiv>
      </Content>
    </PageWrapper>
  );
};

export default DirectRegister;
