// its middlewear to handle reducer call to update a state

import { postData } from "../../../backend/api";
import CustomRoutes from "../../../components/project/router/custom";

const fetchLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_LOGIN_LOADING",
    });
    postData(data, "auth/login")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            let currentMenu = response.data.menu.find(
              (item) => item.path === "/dashboard"
            );
            currentMenu = currentMenu
              ? currentMenu
              : response.data.menu.find((item) => !item.submenu);
            let data = {
              ...response.data,
              menu: [...response.data?.menu, ...CustomRoutes()],
            };
            dispatch({
              type: "MENU_STATUS",
              payload: false,
            });
            dispatch({
              type: "SELECTED_MENU",
              payload: currentMenu ?? { label: "dashboard", icon: "dashboard" },
            });
            dispatch({
              type: "CURRENT_MENU",
              payload: currentMenu.label ?? "dashboard",
            });
            dispatch({
              type: "FETCH_USER_LOGIN_SUCCESS",
              payload: data,
            });
          } else {
            dispatch({
              type: "FETCH_USER_LOGIN_ERROR",
              payload: response.data.message ?? "Something went wrong!",
            });
          }
        } else {
          dispatch({
            type: "FETCH_USER_LOGIN_ERROR",
            payload: "validationFailed",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_USER_LOGIN_ERROR",
          payload: "validationFailed",
        });
      });
  };
};
const clearLogin = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_USER_LOGIN",
    });
  };
};
const clearLoginSession = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_USER_LOGIN_SESSION",
    });
  };
};
const udpateLogin = (data) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_USER_LOGIN_SUCCESS",
      payload: data,
    });
  };
};
export { fetchLogin, clearLogin, clearLoginSession, udpateLogin };
