import React, { useEffect, useState } from "react";
import Layout from "../../../core/layout";
import ListTable from "../../../core/list/list";
import { Container } from "../../../core/layout/styels";
//src/components/styles/page/index.js
//if you want to write custom style wirte in above file
const CertificationData = (props) => {
    //to update the page title
    useEffect(() => {
        document.title = `Certification Data - EventHex Portal`;
    }, []);

    const [attributes] = useState([
        {
            type: "select",
            placeholder: "Type",
            name: "type",
            validation: "",
            default: "",
            label: "Type",
            showItem: "Type",
            required: false,
            view: true,
            filter: false,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "badge,certificate",
        },
        {
            type: "select",
            placeholder: "Layer Type",
            name: "layerType",
            validation: "",
            default: "",
            label: "Layer Type",
            showItem: "Layer Type",
            tag: true,
            required: false,
            view: true,
            filter: false,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "BackgroundImage,BackgroundGradient,Text,Image,Qr",
        },
        {
            type: "select",
            placeholder: "Source",
            name: "source",
            condition: {
                item: "layerType",
                if: "BackgroundImage",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Source",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "BackgroundImageUrl",
        },
        {
            type: "select",
            placeholder: "Position",
            name: "position",
            condition: {
                item: "layerType",
                if: "BackgroundImage",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Position",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "cover,contain",
        },
        {
            type: "select",
            placeholder: "Source",
            name: "source",
            condition: {
                item: "layerType",
                if: "BackgroundGradient",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Source",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "ColourPicker",
        },
        {
            type: "select",
            placeholder: "Source",
            name: "source",
            condition: {
                item: "layerType",
                if: "Text",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Source",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "FieldName",
        },
        {
            type: "text",
            placeholder: "Top",
            name: "top",
            condition: {
                item: "layerType",
                if: "Text",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Top",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Bottom",
            name: "bottom",
            condition: {
                item: "layerType",
                if: "Text",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Bottom",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Left",
            name: "left",
            condition: {
                item: "layerType",
                if: "Text",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "auto",
            tag: true,
            label: "Left",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Right",
            name: "right",
            condition: {
                item: "layerType",
                if: "Text",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Right",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "select",
            placeholder: "Alignment",
            name: "textAlignment",
            condition: {
                item: "layerType",
                if: "Text",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Alignment",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "Left,Right,Justify,Center",
        },
        {
            type: "select",
            placeholder: "Alignment",
            name: "textAlignment",
            condition: {
                item: "layerType",
                if: "Image",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Alignment",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "Left,Right,Justify,Center",
        },
        {
            type: "text",
            placeholder: "Top",
            name: "top",
            condition: {
                item: "layerType",
                if: "Image",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Top",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Bottom",
            name: "bottom",
            condition: {
                item: "layerType",
                if: "Image",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Bottom",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Left",
            name: "left",
            condition: {
                item: "layerType",
                if: "Image",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "auto",
            tag: true,
            label: "Left",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Right",
            name: "right",
            condition: {
                item: "layerType",
                if: "Image",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Right",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "select",
            placeholder: "Position",
            name: "position",
            condition: {
                item: "layerType",
                if: "Image",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Position",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "cover,contain",
        },
        {
            type: "select",
            placeholder: "Shape",
            name: "shape",
            condition: {
                item: "layerType",
                if: "Image",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Shape",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "round,square,curved",
        },
        {
            type: "select",
            placeholder: "Alignment",
            name: "textAlignment",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Alignment",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "Left,Right,Justify,Center",
        },
        {
            type: "text",
            placeholder: "Top",
            name: "top",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Top",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Bottom",
            name: "bottom",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Bottom",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Left",
            name: "left",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "auto",
            tag: true,
            label: "Left",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Right",
            name: "right",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "10px",
            tag: true,
            label: "Right",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "select",
            placeholder: "Source",
            name: "source",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Source",
            required: false,
            view: true,
            add: true,
            update: true,
            apiType: "CSV",
            selectApi: "FieldName",
        },
        {
            type: "text",
            placeholder: "Prefix",
            name: "prefix",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Prefix",
            required: false,
            view: true,
            add: true,
            update: true,
        },
        {
            type: "text",
            placeholder: "Suffix",
            name: "suffix",
            condition: {
                item: "layerType",
                if: "Qr",
                then: "enabled",
                else: "disabled",
            },
            showItem: "",
            validation: "",
            default: "",
            tag: true,
            label: "Suffix",
            required: false,
            view: true,
            add: true,
            update: true,
        },
    ]);

    return (
        <Container className="noshadow">
            <ListTable
                // actions={actions}
                api={`certification-data`}
                itemTitle={{ name: "type", type: "text", collection: "" }}
                shortName={`Certification Data`}
                formMode={`double`}
                {...props}
                attributes={attributes}
            ></ListTable>
        </Container>
    );
};
// exporting the page with parent container layout..
export default Layout(CertificationData);
