import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { ElementContainer, Select } from "../../elements";
import { Title } from "../styles";
import { getData } from "../../../../backend/api";
const PieChart = ({
  title = "Target vs Reality",
  type = "pie", //doughnut
  cutout = "70%",
  dataType = "API",
  filterType = "JSON",
  filters = [
    { id: 1, value: "January" },
    { id: 2, value: "February" },
    { id: 3, value: "March" },
  ],
  dataItem = "dashboard/piechart",
}) => {
  const chartRef = useRef(null);
  const [filterItems, setFilterItems] = useState(filters);
  const [mainData, setMainData] = useState(dataItem);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (dataType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData(filter ? { filter } : {}, api);
          setMainData(response.data);
        } catch (error) {
          console.error("Error fetching data from API:", error);
        }
      };
      fetchData(dataItem);
    }
  }, [dataItem, dataType, filter]);

  useEffect(() => {
    if (filterType === "API") {
      const fetchData = async (api) => {
        try {
          const response = await getData({}, api);
          setFilterItems(response.data);
        } catch (error) {
          console.error("Error fetching filter items from API:", error);
        }
      };
      fetchData(filters);
    }
  }, [filters, filterType]);

  useEffect(() => {
    if (!chartRef.current || !Array.isArray(mainData)) return;
    const maxValue = Math.max(...mainData.map((item) => item.value));

    const data = {
      labels: mainData.map((item) => item.label),
      datasets: [
        {
          data: mainData.map((item) => item.value),
          fill: true,
          tension: 1,
          borderRadius: 10,
          borderSkipped: true,
          backgroundColor: mainData.map((item) => {
            const hue = (item.value / maxValue) * 360; // Convert value to hue (0-360)
            return `hsl(${hue}, 70%, 50%)`; // Fixed saturation and lightness
          }),
        },
      ],
    };

    const ctx = chartRef.current.getContext("2d");

    // Check if there is an existing chart instance
    if (chartRef.current.chart) {
      chartRef.current.chart.destroy();
    }

    chartRef.current.chart = new Chart(ctx, {
      type,
      data: {
        labels: data.labels,
        datasets: data.datasets,
      },
      options: {
        aspectRatio: 1.5, // 3:2 aspect ratio
        cutout,
        plugins: {
          legend: {
            display: true,
            position: "right",
            align: "center",
            fullWidth: false,
            reverse: false,
            labels: {
              color: "black",
              font: {
                size: 12,
                style: "normal",
              },
            },
          },
        },
      },
    });
  }, [type, cutout, mainData]);
  return (
    <ElementContainer className="dashitem column">
      <Title>
        <span>{title}</span>
        <Select
          label="Month"
          align="right small"
          value={filter}
          selectApi={filterItems}
          onSelect={(item) => {
            setFilter(item.id ?? null);
          }}
        ></Select>
      </Title>
      <canvas ref={chartRef} />
    </ElementContainer>
  );
};

export default PieChart;
