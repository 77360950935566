import { Route } from "react-router-dom";
import EventsLanding from "../pages/landing/eventsLanding";
import EventDetailLanding from "../pages/landing/eventDetailLanding";
import Idcard from "../pages/landing/idcard";
import React from "react";
import Luckydraw from "../pages/landing/luckydraw";
import Token from "../pages/landing/token";

const CustomPublicRoute = () => [
  <Route key="events" path="/events" element={<EventsLanding></EventsLanding>} />,
  <Route key="lucky-draw" path="/lucky-draw" element={<Luckydraw></Luckydraw>} />,
  <Route key="event-details" path="/events/:slug" element={<EventDetailLanding />} />,
  <Route key="id-card" path="/my-id-card/:event/:slug" element={<Idcard />} />, // Add the custom redirect route here
  <Route key="get-tokent" path="/get-token" element={<Token />} />, // Add the custom redirect route here
];
export default CustomPublicRoute;
