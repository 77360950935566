import styled from "styled-components";
export const DashBox = styled.div`
  border: 1px solid #e2e4e9;
  border-radius: 12px;
  width: 100%;
  width: calc(${({ width }) => width} - 10px);
  min-height: 150px;
  overflow: auto;
  box-sizing: border-box;
`;
