import { GetAccessToken, SetAccessToken } from "../authentication";
import axios from "axios";

// You can now use the formData object as needed, such as sending it via AJAX or submitting a form

const postData = async (fields, ulr, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let token = GetAccessToken();
      const formData = new FormData();

      let isUploading = false;
      Object.entries(fields).forEach(([key, value]) => {
        if (typeof value === "object") {
          if (value[0] instanceof File) {
            formData.append(key, value[0]);
            isUploading = true;
          } else {
            value.forEach((item, index) => {
              formData.append(`${key}[${index}]`, item);
            });
          }
        } else {
          formData.append(key, value);
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API}${ulr}`,
        formData,
        {
          headers: {
            "Content-Type": isUploading
              ? "multipart/form-data"
              : "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }
      if (response.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
          return resolve({ status: response.status, data: [] });
        } catch (error) {
          console.log(error);
        }
      }

      resolve({ status: response.status, data: response.data });
    } catch (error) {
      // console.log("error", error);
      resolve({
        status: error?.response?.status,
        customMessage:
          error?.response?.data?.customMessage ?? "Something went wrong!",
        data: error?.response?.data?.response,
      });
    }
  });

  return data;
};

const putData = async (fields, ulr, dispatch, navigate) => {
  try {
    let token = GetAccessToken();
    let formData = new FormData();
    const apiUrl = process.env.REACT_APP_API;
    let isUploading = false;
    Object.entries(fields).forEach(([key, value]) => {
      if (typeof value === "object") {
        if (value[0] instanceof File) {
          isUploading = true;
          console.log(value[0] instanceof File);
          formData.append(key, value[0]);
        } else {
          value.forEach((item, index) => {
            formData.append(`${key}[${index}]`, item);
          });
        }
      } else {
        formData.append(key, value);
      }
    });
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    const response = await axios.put(`${apiUrl}${ulr}`, formData, {
      headers: {
        "Content-Type": isUploading
          ? "multipart/form-data"
          : "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const tokenUpdated = response.headers.get("Token-Updated");
    if (tokenUpdated === "true") {
      const newToken = response.headers.get("Authorization").split(" ")[1];
      SetAccessToken(newToken);
    }
    if (response.status === 401) {
      try {
        localStorage.clear();
        window.location.href = "/";
        return { status: response.status, data: [] };
      } catch (error) {
        console.log(error);
      }
    }
    return { status: response.status, data: response.data };
  } catch (error) {
    console.log(error);
    return {
      status: error.response?.status,
      customMessage:
        error.response?.data?.customMessage ?? "Something went wrong!",
    };
  }
};
const bulkUploadData = async (formData, url, dispatch, navigate) => {
  try {
    let token = GetAccessToken();

    const response = await axios.post(
      `${process.env.REACT_APP_API}${url}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
        },
      }
    );
    const tokenUpdated = response.headers.get("Token-Updated");
    if (tokenUpdated === "true") {
      const newToken = response.headers.get("Authorization").split(" ")[1];
      SetAccessToken(newToken);
    }
    // Handle unauthorized access
    if (response.status === 401) {
      // dispatch(clearLogin()); // Uncomment if you have a clearLogin action
      navigate("/");
      navigate(0);
    }

    return { status: response.status, data: response.data };
  } catch (error) {
    console.log("error", error);
    return {
      status: error.response?.status ?? error,
      data:
        error.response?.data?.message || "An error occurred while uploading.", // Provide a default message
    };
  }
};

const getData = async (fields, ulr, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let queryString = Object.keys(fields)
        .map((key) => key + "=" + fields[key])
        .join("&");
      let token = GetAccessToken();
      const hasQueryString = ulr.includes("?");
      const separator = hasQueryString ? "&" : "?";
      const fullUrl = `${process.env.REACT_APP_API}${ulr}${separator}${queryString}`;

      const response = await axios.get(fullUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }
      if (response.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
        } catch (error) {
          console.log(error);
        }
      }
      resolve({ status: response.status, data: response.data });
    } catch (error) {
      if (error.response?.status) {
        if (error.response?.status === 401) {
          try {
            localStorage.clear();
            window.location.href = "/";
          } catch (error) {
            console.log(error);
          }
        }
      }
      resolve({
        status: error.response?.status,
        data: error.response?.data?.message,
      });
    }
  });
  return data;
};
const deleteData = async (fields, ulr, dispatch, navigate) => {
  const data = new Promise(async (resolve, reject) => {
    try {
      let token = GetAccessToken();
      let queryString = Object.keys(fields)
        .map((key) => key + "=" + fields[key])
        .join("&");
      const response = await axios.delete(
        `${process.env.REACT_APP_API}${ulr}?${queryString}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      const tokenUpdated = response.headers.get("Token-Updated");
      if (tokenUpdated === "true") {
        const newToken = response.headers.get("Authorization").split(" ")[1];
        SetAccessToken(newToken);
      }
      if (response.status === 401) {
        try {
          localStorage.clear();
          window.location.href = "/";
        } catch (error) {
          console.log(error);
        }
      }
      resolve({ status: response.status, data: response.data });
    } catch (error) {
      console.log("API Eror", error.message);
      resolve({
        status: error.response.status,
        customMessage:
          error.response?.data?.customMessage ?? "Something went wrong!",
      });
    }
  });

  return data;
};
export { getData, postData, putData, deleteData, bulkUploadData };
