export const addSelectObject = (payload, api) => ({
  type: "ADD_SELECT_OBJECT",
  payload: payload,
  api: api,
});
export const clearSelectObject = (payload, api) => ({
  type: "CLEAR_SELECT_OBJECT",
  payload: payload,
  api: api,
});
