import styled from "styled-components";
export const Titles = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
export const Summary = styled.div`
  font-size: 12px;
  color: grey;
`;
export const DashboardSection = styled.div`
  margin: 30px;
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 250px);
  height: auto;
  align-content: flex-start;
  @media screen and (max-width: 560px) {
    grid-template-columns: auto;
    width: auto;
  }
`;

export const Tile = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  border-radius: 12px;
  justify-content: space-between;
  align-items: left;
  height: 100%;
  box-sizing: border-box;
`;
export const TitleBox = styled.div`
  margin-top: 0;
  display: flex;
  -webkit-box-pack: left;
  justify-content: space-between;
  flex-direction: row;
`;
export const TitleHead = styled.span`
  font-size: 12px;
  color: gray;
  &.info {
    color: Blue;
  }
`;

export const Count = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const IconWrapper = styled.div`
  font-size: 14px;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  align-self: flex-start;
  margin-bottom: 10px;
`;
export const TileContainer = styled.div`
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: 20px;
`;
