import { useEffect, useState } from "react";
import { Footer } from "../../../../../../core/form/styles";
import FormInput from "../../../../../../core/input";
import styled from "styled-components";
const FormBox = styled.div`
  position: absolute;
  z-index: 1001;
  top: 0;
  right: 0;
  background: white;
  left: 0;
  bottom: 0;
  overflow: auto;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  .center {
    margin: auto;
  }
  label {
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 0;
  }
`;
const OtpContainer = styled.div`
  display: flex;
  margin: 20px auto;
  justify-content: center;
`;

const OtpBox = styled.input`
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  border: none;
  border-bottom: 2px solid #ccc;
  margin: 0 5px;
  outline: none;

  /* Highlight bottom border when input is not empty */
  border-color: ${(props) => (props.value ? "#000" : "#ccc")};
`;

const OTPForm = ({ handleSubmit, authenticationId, error, resendOtp, event, country }) => {
  const [otp, setOTP] = useState(["", "", "", ""]);
  const [timer, setTimer] = useState(60);
  const [otpEnterd, setOTPEntered] = useState(false);
  const [reset, setReset] = useState(false);
  // Timer logic
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    if (reset && timer === 0) {
      setTimer(90); // Reset timer to 90 seconds
      setReset(false)
    }

    return () => clearInterval(interval);
  }, [timer, reset]);

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    if (index < 3) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
    setOTP(newOtp);
    const isFilled = newOtp.every((digit) => digit !== "");
    setOTPEntered(isFilled);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      // Move focus to the previous box on Backspace
      const newOtp = [...otp];
      newOtp[index - 1] = "";
      setOTP(newOtp);
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  return (
    <FormBox
      onSubmit={(event) => {
        event.preventDefault();
        // alert("yes");
      }}
      action="#"
      method="POST"
      className={`plain`}
    >
      <div className="center">
        <label>Enter OTP</label>
        <div style={{ marginTop: "20px", fontSize: "14px", textAlign: "center" }}>We have sent an OTP to your WhatsApp number '{"+"+country.phoneCode+authenticationId}'.</div>
        <OtpContainer>
          {otp.map((digit, index) => (
            <OtpBox placeholder="-" key={index} id={`otp-${index}`} type="text" maxLength="1" value={digit} onChange={(e) => handleChange(index, e.target.value)} onKeyDown={(e) => handleKeyDown(e, index)} />
          ))}
        </OtpContainer>
        <div style={{ marginTop: "10px", marginBottom: "10px", color: "red", fontSize: "14px", textAlign: "center" }}>{error}</div>
        {/* <TextBox type="number" label="Enter OTP" value={otp} onChange={handleChange} maxLength={4} placeholder="Enter OTP" /> */}
        <Footer className={"center"}>
          <FormInput
            disabled={timer > 0}
            type="close"
            value={timer > 0 ? `Resend OTP (${timer}s)` : "Resend OTP"}
            onChange={() => {
              setReset(true);
              resendOtp();
            }}
          />
          <FormInput disabled={!otpEnterd} type="submit" name="submit" value={"Submit OTP"} onChange={() => handleSubmit({ otp: otp.join("") })} />
        </Footer>
      </div>
    </FormBox>
  );
};

export default OTPForm;
